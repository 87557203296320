import React from "react";
import { ArrowSmDownIcon, ArrowSmUpIcon, ArrowSmRightIcon } from "assets/icons";

/**
 * Accepts a current and previous value displaying the difference
 * @param {*} props
 * @returns A metric chart component
 */
export function TrendChart({
  id,
  keys,
  data = [],
  settings = {
    showTitle: false,
    inverted: true,
    durationUnit: "Days",
  },
  ...other
}) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const key = keys[0] ?? null; // First key is index
  const previous = data[1]; // previous unit
  const current = data[0]; // current unit
  const difference =
    previous && current ? current[key?.id] - previous[key?.id] : 0;
  const hasIncreased = difference > 0 ? true : difference < 0 ? false : null;

  return (
    <div {...other} className="px-4 py-5 sm:p-6">
      <div>
        {!!settings.showTitle && (
          <div className="text-base font-normal text-gray-900">
            {key?.title}
          </div>
        )}
      </div>
      <div className="mt-1 flex justify-between items-baseline md:block lg:flex">
        {previous && current && keys.length ? (
          <div className="text-2xl font-semibold text-indigo-600 text-center">
            {!!previous ? previous[key?.id] : NaN}
            <div className="text-xs font-medium text-gray-500">
              Previous
            </div>
          </div>
        ) : null}

        {previous && current && keys.length ? (
          <div className="text-2xl font-semibold text-indigo-600 text-center">
            {!!current ? current[key?.id] : NaN}
            <div className="text-xs font-medium text-gray-500">
              Current
            </div>
          </div>
        ) : null}

        <div
          className={classNames(
            difference === 0
              ? "bg-gray-100"
              : (difference > 0 && !settings.inverted) ||
                (difference < 0 && settings.inverted)
              ? "bg-success-100 text-success-800"
              : "bg-danger-100 text-danger-800",
            "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
          )}
        >
          {hasIncreased === true ? (
            <ArrowSmUpIcon
              className="-ml-1 mr-0.5 shrink-0 self-center h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          ) : hasIncreased === false ? (
            <ArrowSmDownIcon
              className="-ml-1 mr-0.5 shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <ArrowSmRightIcon
              className="-ml-1 mr-0.5 shrink-0 self-center h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          )}

          <span className="sr-only">
            {hasIncreased === true
              ? "Increased"
              : !hasIncreased === false
              ? "Decreased"
              : "Same"}{" "}
            by
          </span>
          {difference.toFixed(2)}
        </div>
      </div>
    </div>
  );
}
