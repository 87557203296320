import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import React, { createContext, useState, useCallback } from "react";

export const NavContext = createContext(undefined);

export function NavProvider({ children }) {
  const [optionsToShow, setOptionsToShow] = useState(undefined);

  //#region Callbacks

  const handleSetOptionsToShow = useCallback((id) => {
    if (isNullEmptyOrWhitespace(id)) return;

    setOptionsToShow((prevState) => (prevState !== id ? id : prevState));
  }, []);

  const handleOptionsToHide = useCallback((id) => {
    if (isNullEmptyOrWhitespace(id)) return;

    setOptionsToShow((prevState) =>
      prevState === id ? (prevState !== false ? false : prevState) : prevState
    );
  }, []);

  //#endregion

  return (
    <NavContext.Provider
      value={{
        setOptionsToShow: handleSetOptionsToShow,
        setOptionsToHide: handleOptionsToHide,
        optionsToShow,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
