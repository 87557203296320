import React, { useContext, useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import Historical from "components/Historical";
import { useNavigate } from "react-router-dom";
import { AppDataContext, useFarms, useUser } from "context/AppDataProvider";
import useQuery from "hooks/useQuery";
import ButtonNew from "components/Historical/ButtonNew";
import useDeepCompareEffect from "use-deep-compare-effect";
import ButtonSkeleton from "components/core/Buttons/ButtonSkeleton";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { buildFormPageUrl } from "helpers/redirectUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { deepClone } from "helpers/dataUtilities";
import Card from "components/Card";
import { IFarm } from "helpers/farmUtilities";
import { IForm, IFormData } from "helpers/formUtilities";
import { IButtonNew } from "components/Historical/types";
import Dashboard from "components/Dashboard";
// import FarmSelector from "components/FarmSelector";
// import HouseSelector from "components/HouseSelector";
// import MenuSelector from "components/MenuSelector";
import Breadcrumb from "components/Breadcrumb";
import Tabs from "components/Tabs";
import { filterUserDashboardsByMenuId } from "helpers/dashboardUtilities";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function ListPage() {
  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  let location = useLocation();
  let navigate = useNavigate();

  let query = useQuery();
  const farmId = query.get("farmId");
  const houseId = query.get("houseId");
  const {
    fetchFormValues,
    forms,
    pageTitle,
    setPageTitle,
    dashboards,
  } = useContext(AppDataContext);
  const { user } = useUser();
  const { farms } = useFarms();

  const [currentView, setCurrentView] = useState<string | undefined>(undefined);

  const farm = useMemo<IFarm | undefined>(() => {
    if (farmId === null) {
      return undefined;
    }

    return farms.find(
      (f: IFarm) => f.FarmCode.toLowerCase() === farmId.toLowerCase()
    );
  }, [farms, farmId]);

  const dashboard = useMemo(() => {
    if (activeMenu?.ID === undefined) {
      return undefined;
    }

    const result = filterUserDashboardsByMenuId(
      dashboards,
      user,
      farms,
      activeMenu?.ID
    );

    return result?.length > 0 ? result[0] : undefined;
  }, [dashboards, farms, activeMenu?.ID, user]);

  // const house = useMemo(() => {
  //   if (houseId === null || farm === undefined) {
  //     return undefined;
  //   }

  //   return farm.Houses?.find(
  //     (h) => h.HouseNumber.toString() === houseId.toString()
  //   );
  // }, [farm, houseId]);

  const filteredForms = useMemo(() => {
    let filteredForms = forms.filter(
      (f) =>
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName?.toLowerCase() === module?.toLowerCase()
    );

    const formsClone = deepClone(filteredForms) as IForm[];

    formsClone.forEach((f) => {
      // Filter form fields by FarmType
      if (farm?.FarmType) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmType) ||
            ff.FarmType?.split(",").some(
              (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
            )
        );
      }

      // Filter form fields by FarmGroup
      if (farm?.FarmGroup) {
        f.FormFields = f.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmGroup) ||
            ff.FarmGroup?.split(",").some(
              (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
            )
        );
      }
    });

    return formsClone;
  }, [forms, module, moduleFeatureGroup, farm?.FarmType, farm?.FarmGroup]);

  const [formData, setFormData] = useState<IFormData[] | undefined>(undefined);
  const [newButtonOptions, setNewButtonOptions] = useState<
    IButtonNew[] | undefined
  >(undefined);

  //#region Side-effects

  /**
   * Set form data
   */
  // TODO: don't need to refetch on house change if previous house has same form data
  // as formvalues-get returns all houses
  useDeepCompareEffect(() => {
    const abortController = new AbortController();

    setFormData(undefined);

    // Forms not yet loaded
    if (!filteredForms.length || farmId === null || houseId === null) {
      return;
    }

    const formIds = (filteredForms as IForm[])
      .filter((f) => f.HasFormValues)
      .map((f) => ({
        formId: f.FormName,
        formType: f.FormType,
        moduleId: f.ModuleName,
      }));

    fetchFormValues(farmId, houseId, formIds, abortController.signal)
      .then((result: IFormData[]) => {
        if (abortController.signal.aborted) return;

        const newFormData = result
          ?.filter((r) => r !== undefined)
          // Flatten array of responses in single response
          .flat()
          // Sort chronologically after flattening array
          .sort(
            (a, b) =>
              b._DateApplies?.normalised.getTime() -
                a._DateApplies?.normalised.getTime() ||
              b._LastModified?.normalised.getTime() -
                a._LastModified?.normalised.getTime()
          );

        setFormData(newFormData);
      })
      .catch((error) => {
        if (abortController.signal.aborted) return;

        console.error(error.message);
      });
  }, [farmId, fetchFormValues, filteredForms, filteredForms.length, houseId]);

  /**
   * Set page title
   */
  useEffect(() => {
    setPageTitle(activeMenu?.Title ?? "");
  }, [location.pathname, setPageTitle, activeMenu?.Title]);

  /**
   * Set new button options
   */
  useDeepCompareEffect(() => {
    const _forms = filteredForms.filter(
      (f) =>
        f.Permissions.includes("view") &&
        f.Permissions.includes("create") &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase()
    );

    setNewButtonOptions(
      _forms.map((f) => ({
        FormName: f.FormName,
        FormTitle: f.FormTitle,
        FormType: f.FormType,
      }))
    );
  }, [filteredForms, moduleFeatureGroup]);

  //#endregion

  //#region Listeners

  function handleButtonNewClick(formType: string, formName: string) {
    return navigate(buildFormPageUrl(activeMenu, formName));
  }

  //#endregion

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <div className="flex flex-row items-center">
            <div className="text-lg text-gray-600 uppercase flex-grow font-medium">
              {pageTitle}
            </div>
            {newButtonOptions !== undefined && filteredForms.length > 0 ? (
              // <div className="absolute bottom-4 right-4">
              <ButtonNew
                className="justify-end"
                onClick={handleButtonNewClick}
                options={newButtonOptions}
              />
            ) : (
              // </div>
              <ButtonSkeleton />
            )}
          </div>
        </div>

        {!!dashboard && farmId !== null && houseId !== null && (
          <div className="col-span-full">
            <Tabs
              tabs={[
                {
                  name: "List",
                  id: "list",
                  current: currentView === undefined || currentView === "list",
                },
                {
                  name: "Dashboard",
                  id: "dashboard",
                  current: currentView === "dashboard",
                },
              ]}
              onChange={(id: string) => setCurrentView(id)}
            ></Tabs>
          </div>
        )}

        {(currentView === "list" || currentView === undefined) && (
          <div className="col-span-full">
            <Card>
              {farmId !== null && houseId !== null && formData !== undefined ? (
                <div className="flex flex-col flex-grow space-y-4">
                  <Historical
                    className="-mx-4"
                    farmId={farmId.toLowerCase()}
                    houseId={houseId.toString()}
                    forms={filteredForms.filter((f) =>
                      f.Permissions.includes("view")
                    )}
                    items={formData}
                  />
                </div>
              ) : (
                <ListSkeleton />
              )}
            </Card>
          </div>
        )}

        {currentView === "dashboard" && (
          <div className="col-span-full">
            <Dashboard dashboard={dashboard} />
          </div>
        )}
      </div>
    </main>
  );
}
