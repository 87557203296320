import { IMenuItem } from "components/Sidebar";
import { useMenus, useUser } from "context/AppDataProvider";
import { extractParamsFromUrlPath } from "helpers/redirectUtilities";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface IActiveMenuContext {
  activeMenu: IMenuItem | undefined;
}

export interface IActiveMenuProviderProps {
  children: React.ReactNode;
}

const ActiveMenuContext = createContext({} as IActiveMenuContext);

export function ActiveMenuProvider({ children }: IActiveMenuProviderProps) {
  const { pathname } = useLocation();
  const { menus } = useMenus();
  const { user } = useUser();

  const activeMenu = useMemo(() => {
    let menu;
    let parentMenu;

    const path = extractParamsFromUrlPath(pathname);
    const joinedPath = `/${path.slice(0, 2).join("/")}`;

    for (const parent of menus) {
      if (parent?.Children === undefined) continue;

      for (const child of parent.Children) {
        if (child.Path.toLowerCase() === joinedPath.toLowerCase()) {
          menu = child;
          parentMenu = parent;
          break;
        }
      }
    }

    if (!menu || !parentMenu) {
      return undefined;
    }

    const result = {
      ...menu,
      Parent: parentMenu,
    };

    return result;
  }, [menus, pathname]);

  useEffect(() => {
    if (activeMenu?.ID === undefined || user?.UserName === undefined) {
      return;
    }
    saveRecentVisit(user?.UserName, activeMenu.ID);
  }, [activeMenu?.ID, user?.UserName]);

  return (
    <ActiveMenuContext.Provider value={{ activeMenu }}>
      {children}
    </ActiveMenuContext.Provider>
  )
}

export function useActiveMenu() {
  const context = useContext(ActiveMenuContext);

  
  if (!context) {
    throw new Error("useActiveMenu must be used within a React Router Route.");
  }

  return context;
}

function saveRecentVisit(userName: string, menuId: string) {
  if (!userName || !menuId) {
    return;
  }

  const recentVisits = localStorage.getItem(`recentVisits-${userName}`);
  const recentVisitsJson = JSON.parse(recentVisits || "[]");
  const recentVisitsUpdated = recentVisitsJson
    .filter((r: string) => r !== menuId)
    .slice(0, 3);
  recentVisitsUpdated.unshift(menuId);
  localStorage.setItem(
    `recentVisits-${userName}`,
    JSON.stringify(recentVisitsUpdated)
  );
}
