import { useState, useEffect, useCallback } from "react";
import { debounce } from "helpers/performanceUtilities";

const useSticky = ({ defaultSticky = false, inverted = false }) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const [targetElement, setTargetElement] = useState(undefined);

  //#region Callbacks

  /**
   * Set target element handler
   */
  const handleSetTargetElement = useCallback((node) => {
    setTargetElement(node);
  }, []);

  /**
   * Toggle sticky based on scroll position to element
   */
  const toggleSticky = useCallback(
    ({ top, bottom }) => {
      const yPosition = getYPosition(!inverted);
      if ((inverted && yPosition <= top) || (!inverted && yPosition >= top)) {
        !isSticky && setIsSticky(true);
      } else {
        isSticky && setIsSticky(false);
      }
    },
    [isSticky, inverted]
  );

  /**
   * Handle scroll events to trigger toggleSticky check
   */
  const handleScroll = useCallback(() => {
    if (
      targetElement?.offsetTop === undefined ||
      targetElement?.offsetHeight === undefined ||
      toggleSticky === undefined
    )
      return;

    toggleSticky({
      top: targetElement.offsetTop + targetElement.offsetHeight,
      bottom: targetElement.offsetTop - targetElement.offsetHeight,
    });
  }, [targetElement?.offsetTop, targetElement?.offsetHeight, toggleSticky]);

  /**
   * Debounced handle scroll events to improve performance,
   * scrolling is triggered an awful lot!
   */
  const handleScrollDebounced = debounce(function() {
    handleScroll();
  }, 100);

  /**
   * Get the current scroll Y position of the browser page.
   * @param {*} top 
   * @returns 
   */
  function getYPosition(top = true) {
    if (top) return window.pageYOffset;
    else return window.pageYOffset + window.innerHeight;
  }

  //#endregion

  //#region Side-effects

  /**
   * Every rerender...
   */
  useEffect(() => {
    handleScrollDebounced();
  });

  /**
   * When target element is set, listen for scroll events
   */
  useEffect(() => {
    if (!toggleSticky || !targetElement) return;

    handleScroll();
    window.addEventListener("scroll", handleScrollDebounced);
    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [toggleSticky, targetElement, handleScroll, handleScrollDebounced]);

  //#endregion

  return { targetRef: handleSetTargetElement, isSticky };
};
export default useSticky;
