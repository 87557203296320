import React, { useEffect, useCallback, useRef } from "react";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { getReadonlyFormValueByFieldType } from "helpers/formsUtilities";
import { Text } from "components/core";
import { ModalContext } from "context/ModalProvider";
import { BadgeSeverity } from "./BadgeSeverity";
import { useAppConfig } from "context/AppDataProvider";
import { getAbsoluteUrl } from "helpers/mediaUtilities";

export default function NonConformanceReadonlyField({
  label,
  formValue,
  defaultValue,
  setValue,
  render,
  className,
  field,
  severityColour,
  labelPosition,
  ...other
}) {
  const { config } = useAppConfig();

  //#region Callbacks

  const prevValueRef = useRef(null);

  const { setShowModal } = React.useContext(ModalContext);

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      if (value !== prevValueRef.current) {
        setValue(value);
        prevValueRef.current = value;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  //#endregion

  //#region Side-effects

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (!defaultValue || !setInputValue) return;

    if (isNullEmptyOrWhitespace(formValue?.Value)) {
      setInputValue(defaultValue);
    }
  }, [formValue?.Value, defaultValue, setInputValue]);

  //#endregion

  const onClickImage = useCallback((url) => {
    setShowModal(true, <img src={url} alt={url} />);
  }, [setShowModal]);

  // Prevent dom element rendering
  if (render === false) {
    return "";
  }

  const getValue = () => {
    if (isNullEmptyOrWhitespace(field)) return "";

    if (field.FieldType.toLowerCase() === "up") {
      return (
        <div className="shrink-0 sm:mt-0 sm:ml-5">
          <div className="flex overflow-hidden -space-x-1">
            {getReadonlyFormValueByFieldType(field, formValue)?.map(
              (src) => <Image src={src} configMediaUrl={config.mediaUrl} onClick={onClickImage} />
            )}
          </div>
        </div>
      );
    } else if (field.FieldType.toLowerCase() === "bg") {
      return (
        <BadgeSeverity severity={severityColour}>
          {getReadonlyFormValueByFieldType(field, formValue)}
        </BadgeSeverity>
      );
    } else {
      return getReadonlyFormValueByFieldType(field, formValue);
    }
  };

  if (!render) {
    return null;
  }

  return (
    <div className={className}>
      <Text {...other} label={label} labelPosition={labelPosition} value={getValue()} />
    </div>
  );
}


const Image = ({ src, configMediaUrl, onClick }) => {
  if (isNullEmptyOrWhitespace(configMediaUrl)) {
    return null;
  }
  
  const absoluteUrl = getAbsoluteUrl(src, configMediaUrl);

  return (
    <div
      className="block h-24 w-24 rounded-full cursor-pointer bg-gray-100 shadow-md border-2 border-white ring-1 ring-white hover:border-primary relative hover:z-10 overflow-hidden print:bg-transparent"
      onClick={() => onClick(absoluteUrl)}
    >
      <img
        key={src}
        className="object-cover min-h-full min-w-full pointer-events-none print:w-2/3 print:mx-auto"
        src={absoluteUrl}
        alt={src}
      />
    </div>
  )
};