import { Button, Input, Select, Toggle } from "components/core";
import { CHART_TYPE } from "constants.js";
import { IChart } from "../types";

interface IEditChartSettingsForm {
  chart: IChart;
  onChangeTitle: (title: string) => void;
  onClickSave: () => void;
  onChangeSettings: (key: string, value: any) => void;
  onChangeAttr: (key: string, value: any) => void;
}

export function EditChartSettingsForm(props: IEditChartSettingsForm) {
  return (
    <div className="grid grid-cols-1 gap-6 py-4">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-full">
            <Input
              id="title-input"
              key={`title-input`}
              label="Metric Title"
              labelPosition="inset"
              hint="Override the metric title"
              value={props.chart.title}
              setValue={props.onChangeTitle}
              placeholder={props.chart.title}
              required={false}
              disableCalcTrigger={true}
            />
          </div>

          <div className="col-span-full grid grid-cols-2 gap-2">
            <Input
              id="record-count-input"
              key={`record-count-input`}
              label="Record count"
              labelPosition="inset"
              value={props.chart?.settings?.recordCount ?? ""}
              setValue={(value: string) =>
                props.onChangeSettings("recordCount", value)
              }
              placeholder="E.g. 10"
              required={false}
              disableCalcTrigger={true}
            />
            <Select
              id="layout-select"
              label="Layout"
              value={props.chart?.settings?.layout ?? "vertical"}
              listOptions={[
                {
                  Id: "horizontal",
                  Text: "Horizontal",
                  Value: "horizontal",
                },
                {
                  Id: "vertical",
                  Text: "Vertical",
                  Value: "vertical",
                },
              ]}
              setValue={(value: string) =>
                props.onChangeSettings("layout", value)
              }
              labelPosition="inset"
              required={false}
              disableCalcTrigger={true}
            />
          </div>


          <div className="col-span-full grid grid-cols-2 gap-2">
            <Toggle
              id="show-title-toggle"
              key={`show-title-toggle`}
              label="Show title"
              value={props.chart.settings.showTitle}
              setValue={(value: boolean) =>
                props.onChangeSettings("showTitle", value)
              }
              checkedValue={true}
              uncheckedValue={false}
              labelPosition="inset"
              required={false}
              disableCalcTrigger={true}
            />

            {[CHART_TYPE.TABLE].includes(
              props.chart.type as any
            ) && (
              <Toggle
                id="show-config-toggle"
                key={`show-config-toggle`}
                label="Show config"
                value={props.chart.settings.showConfig}
                setValue={(value: boolean) =>
                  props.onChangeSettings("showConfig", value)
                }
                checkedValue={true}
                uncheckedValue={false}
                labelPosition="inset"
                required={false}
                disableCalcTrigger={true}
              />
            )}
          </div>

          {[CHART_TYPE.TABLE, CHART_TYPE.BAR].includes(
            props.chart.type as any
          ) && (
            <div className="col-span-full">
              <div className="py-4 space-y-2 border-t border-b border-gray-200">
                <div className="text-xs text-gray-500">Sort by...</div>
                <div className="col-span-full grid grid-cols-2 gap-2">
                  <Select
                    id="sort-by-key"
                    label="Key"
                    value={props.chart?.settings?.sortByKey ?? ""}
                    listOptions={props.chart?.keys?.map((key) => ({
                      Id: key.id,
                      Text: key.title,
                      Value: key.id,
                    }))}
                    setValue={(value: string) =>
                      props.onChangeSettings("sortByKey", value)
                    }
                    labelPosition="inset"
                    required={false}
                    disableCalcTrigger={true}
                  />

                  <Select
                    id="sort-by-direction"
                    label="Direction"
                    value={props.chart?.settings?.sortByDirection ?? "asc"}
                    listOptions={[
                      {
                        Id: "asc",
                        Text: "Ascending",
                        Value: "asc",
                      },
                      {
                        Id: "desc",
                        Text: "Descending",
                        Value: "desc",
                      },
                    ]}
                    setValue={(value: string) =>
                      props.onChangeSettings("sortByDirection", value)
                    }
                    labelPosition="inset"
                    required={false}
                    disableCalcTrigger={true}
                  />
                </div>
              </div>
            </div>
          )}

          {[CHART_TYPE.LINE.toString(), CHART_TYPE.BAR.toString()].includes(
            props.chart.type
          ) && (
            <div className="col-span-full">
              <div className="py-4 space-y-2 border-t border-b border-gray-200">
                <div className="text-xs text-gray-500">Axis label:</div>
                <div className="col-span-full grid grid-cols-2 gap-2">
                  <Toggle
                    id="show-left-axis-label"
                    key="show-left-axis-label"
                    label="Show left"
                    value={props.chart?.settings?.showLeftAxisLabel}
                    setValue={(value: boolean) =>
                      props.onChangeSettings("showLeftAxisLabel", value)
                    }
                    checkedValue={true}
                    uncheckedValue={false}
                    labelPosition="inset"
                    required={false}
                    disableCalcTrigger={true}
                  />
                  <Toggle
                    id="show-bottom-axis-label"
                    key="show-bottom-axis-label"
                    label="Show bottom"
                    value={props.chart?.settings?.showBottomAxisLabel}
                    setValue={(value: boolean) =>
                      props.onChangeSettings("showBottomAxisLabel", value)
                    }
                    checkedValue={true}
                    uncheckedValue={false}
                    labelPosition="inset"
                    required={false}
                    disableCalcTrigger={true}
                  />
                </div>
              </div>
            </div>
          )}

          {[CHART_TYPE.LINE.toString()].includes(props.chart.type) && (
            <div className="col-span-full">
              <Select
                id="widget-curve-type"
                key="widget-curve-type"
                label="Curve type"
                value={props.chart?.attrs?.curveType ?? "monotoneX"}
                listOptions={[
                  {
                    Id: "monotoneX",
                    Text: "monotoneX",
                    Value: "monotoneX",
                  },
                  {
                    Id: "catmullRom",
                    Text: "catmullRom",
                    Value: "catmullRom",
                  },
                  {
                    Id: "linear",
                    Text: "Linear",
                    Value: "linear",
                  },
                  {
                    Id: "basis",
                    Text: "Basis",
                    Value: "basis",
                  },
                  {
                    Id: "step",
                    Text: "Step",
                    Value: "step",
                  },
                ]}
                setValue={(value: string) =>
                  props.onChangeAttr("curveType", value)
                }
                labelPosition="inset"
                required={false}
                disableCalcTrigger={true}
              />
            </div>
          )}

          {[CHART_TYPE.TREND.toString()].includes(props.chart.type) && (
            <div className="col-span-full">
              <Toggle
                id="inverted-toggle"
                key="inverted-toggle"
                label="Inverted"
                value={props.chart?.settings?.inverted}
                setValue={(value: boolean) =>
                  props.onChangeSettings("inverted", value)
                }
                checkedValue={true}
                uncheckedValue={false}
                labelPosition="inset"
                required={false}
                disableCalcTrigger={true}
              />
            </div>
          )}

          {[CHART_TYPE.BAR.toString()].includes(props.chart.type) && (
            <div className="col-span-full">
              <div className="py-4 space-y-2 border-t border-b border-gray-200">
                <div className="text-xs text-gray-500">Margin offset...</div>
                <div className="col-span-full grid grid-cols-2 gap-2">
                  <Input
                    id="margin-top-offset-input"
                    key={`margin-top-offset-input`}
                    label="Top"
                    labelPosition="inset"
                    value={props.chart?.settings?.marginTopOffset}
                    setValue={(value: string) =>
                      props.onChangeSettings("marginTopOffset", value)
                    }
                    placeholder="E.g. 10"
                    required={false}
                    disableCalcTrigger={true}
                  />
                  <Input
                    id="margin-right-offset-input"
                    key={`margin-right-offset-input`}
                    label="Right"
                    labelPosition="inset"
                    value={props.chart?.settings?.marginRightOffset}
                    setValue={(value: string) =>
                      props.onChangeSettings("marginRightOffset", value)
                    }
                    placeholder="E.g. 10"
                    required={false}
                    disableCalcTrigger={true}
                  />
                  <Input
                    id="margin-bottom-offset-input"
                    key={`margin-bottom-offset-input`}
                    label="Bottom"
                    labelPosition="inset"
                    value={props.chart?.settings?.marginBottomOffset}
                    setValue={(value: string) =>
                      props.onChangeSettings("marginBottomOffset", value)
                    }
                    placeholder="E.g. 10"
                    required={false}
                    disableCalcTrigger={true}
                  />
                  <Input
                    id="margin-left-offset-input"
                    key={`margin-top-offset-input`}
                    label="Left"
                    labelPosition="inset"
                    value={props.chart?.settings?.marginLeftOffset}
                    setValue={(value: string) =>
                      props.onChangeSettings("marginLeftOffset", value)
                    }
                    placeholder="E.g. 10"
                    required={false}
                    disableCalcTrigger={true}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="col-span-full">
            <Select
              id="widget-size-trend-select"
              key="widget-size-trend-select"
              label="Widget size"
              value={props.chart?.settings?.widgetSize}
              listOptions={[
                {
                  Id: "small",
                  Text: "25%",
                  Value: "small",
                },
                {
                  Id: "medium",
                  Text: "33%",
                  Value: "medium",
                },
                {
                  Id: "large",
                  Text: "50%",
                  Value: "large",
                },
                {
                  Id: "xlarge",
                  Text: "75%",
                  Value: "xlarge",
                },
                {
                  Id: "xxlarge",
                  Text: "100%",
                  Value: "xxlarge",
                },
              ]}
              setValue={(value: string) =>
                props.onChangeSettings("widgetSize", value)
              }
              labelPosition="inset"
              required={false}
              disableCalcTrigger={true}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <Button
          type="button"
          size="small"
          theme="primary"
          onClick={() => props.onClickSave()}
        >
          Apply &amp; close
        </Button>
      </div>
    </div>
  );
}
