import Breadcrumb from "components/Breadcrumb";
import DynamicForm from "components/forms/DynamicForm";
import { useState } from "react";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { useLocation } from "react-router-dom";

const isHouseRequired = true;

export default function Forms() {
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState<string | React.ReactNode>(
    undefined
  );
  const [pageSubtitle, setPageSubtitle] = useState<string | React.ReactNode>(
    undefined
  );

  return (
    <main className="flex flex-col flex-grow">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb
          key="breadcrumb"
          showHome={false}
          farmRequired={true}
          houseRequired={isHouseRequired}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {pageTitle !== undefined ? (
              pageTitle
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
          {!isNullEmptyOrWhitespace(pageSubtitle) ? (
            <div className="tablet:inline-flex tablet:space-x-4 text-sm">
              {pageSubtitle}
            </div>
          ) : null}
        </div>
        <div className="col-span-full">
          <DynamicForm
            key={location.key}
            setPageTitle={setPageTitle}
            setPageSubtitle={setPageSubtitle}
          />
        </div>
      </div>
    </main>
  );
}
