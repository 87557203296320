import { isNullEmptyOrWhitespace } from "./stringUtilities";

export const fetchMediaByID = async (mediaId: string, signal?: AbortSignal) => {
  const response = await fetch(`/api/media-get?id=${mediaId}&limit=&offset=&order=&orderDirection=`, {
    signal,
    method: "GET",
  });

  return await response.json();
};

export const getAbsoluteUrl = (relativePath: string, mediaUrl: string) => {
  // TODO: This is temporary, we should instead get the absolute URL from the server
  if (isNullEmptyOrWhitespace(mediaUrl)) {
    throw new Error("Media URL is not set in the global configuration");
  }

  if (relativePath.startsWith("http")) {
    return relativePath;
  }

  const imageURL = mediaUrl.replace("%s", relativePath);

  return imageURL;
}

export const getMimeTypeFromUrl = (url: string) => {
  const pathname = new URL(url).pathname;
  const extension = pathname.split(".").pop();
  const extensionLower = extension?.toLowerCase();

  switch (extensionLower) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "webp":
      return "image/webp";
    case "svg":
      return "image/svg+xml";
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "ogg":
      return "video/ogg";
    case "pdf":
      return "application/pdf";
    default:
      return "application/octet-stream";
  }
};