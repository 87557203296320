import React from "react";

/**
 * Accepts a current and previous value displaying the difference
 * @param {*} props
 * @returns A metric chart component
 */
export function MetricChart({
  id,
  keys,
  data = [],
  settings,
  ...other
}) {
  const key = keys[0] ?? null;
  const dataset = data[0];

  return (
    <div {...other} className="px-4 py-5 sm:p-6">
      <div className="mt-1 flex justify-between items-baseline md:block lg:flex">
        <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
          {!!dataset && (dataset[key?.id])}
        </div>
      </div>
    </div>
  );
}
