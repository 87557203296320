import React, { useEffect, useState } from "react";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./applicationInsights";
import { ProvideAuth } from "hooks/useAuth";
import { NotificationProvider } from "context/NotificationProvider";
import { AppDataProvider } from "context/AppDataProvider";
import { NavProvider } from "context/NavProvider";
import Notifications from "components/Notifications";
import AppUpdateNotification from "components/AppUpdateNotification";
import { ModalProvider } from "context/ModalProvider";
import SidebarProvider from "context/SidebarProvider";
import Error500 from "pages/Error500";
import BackgroundSyncNotification from "components/BackgroundSyncNotification";
import CustomRoutes from "routes/CustomRoutes";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

const version = require("../package.json").version;
export const AppVersion = `v${version}`;

export default function App() {
  const [swStatus, setSwStatus] = useState("");

  const waitingWorkerRef = React.useRef(null);

  const handleSwUpdateAvailable = (ev) => {
    console.log("update available");
    waitingWorkerRef.current = ev.detail.waiting;
    setSwStatus("update_available");
  };

  const handleSwInstalling = () => {
    console.log("installing");
    setSwStatus("installing");
  };

  useEffect(() => {
    document.addEventListener(
      "serviceWorkerUpdateAvailable",
      handleSwUpdateAvailable
    );

    document.removeEventListener("serviceWorkerInstalling", handleSwInstalling);

    return () => {
      document.removeEventListener(
        "serviceWorkerUpdateAvailable",
        handleSwUpdateAvailable
      );
      document.removeEventListener(
        "serviceWorkerInstalling",
        handleSwInstalling
      );
    };
  }, []);

  useEffect(() => {
    if (swStatus === "installed") {
      setTimeout(() => {
        setSwStatus("");
      }, 3000);
    }
  }, [swStatus]);

  const handleUpdateApp = () => {
    setSwStatus("installing");

    // delay to allow UI to update
    setTimeout(() => {
      waitingWorkerRef.current.postMessage({ type: "SKIP_WAITING" });
    }, 2000);

    waitingWorkerRef.current.addEventListener("statechange", (e) => {
      // Wait for new service worker to become active
      if (e.target.state === "activated") {
        console.log("activated");
        setSwStatus("installed");

        // Remove the event listener since it's no longer needed
        waitingWorkerRef.current = null;

        window.location.reload();
      }
    });

    // reset status after 10 seconds
    setTimeout(() => {
      if (!isNullEmptyOrWhitespace(swStatus)) {
        console.warn("Resetting SW status due to timeout");
        setSwStatus("");
      }
    }, 10000);
  };

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <Error500 />}
        appInsights={reactPlugin}
      >
        <AppDataProvider>
          <SidebarProvider>
            <NavProvider>
              <NotificationProvider>
                <ModalProvider>
                  <ProvideAuth>
                    <Notifications />
                    <AppUpdateNotification
                      status={swStatus}
                      onClick={handleUpdateApp}
                    />
                    <BackgroundSyncNotification />

                    <CustomRoutes />
                  </ProvideAuth>
                </ModalProvider>
              </NotificationProvider>
            </NavProvider>
          </SidebarProvider>
        </AppDataProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}



