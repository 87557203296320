import { useUser } from "context/AppDataProvider";
import { Navigate, useLocation } from "react-router-dom";

interface IRequireAuthRouteElementWrapperProps {
  children: React.ReactNode;
}

export default function RequireAuthRouteElementWrapper({
  children,
}: IRequireAuthRouteElementWrapperProps) {
  const { isSignedIn } = useUser();
  const location = useLocation();

  if (isSignedIn) {
    return children;
  }

  return <Navigate to="/user/login" replace state={{ from: location }} /> as any;
}
