import { List } from "components/core";
import ListItem from "./ListItemHistorical";
import { localDate } from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

export default function Historical({ farmId, houseId, className, forms = [], items }) {
  const dateToday = localDate();

  //#region Callbacks

  function isChildForm(formValueRecord) {
    return (
      !isNullEmptyOrWhitespace(formValueRecord.ParentPWAID) ||
      !isNullEmptyOrWhitespace(formValueRecord.ParentID)
    );
  }

  //#endregion

  //#region Side-effects

  //#endregion

  //#region Event handlers

  //#endregion

  return (
    <>
      <List theme="striped" size="small" className={className}>
        {items
          ?.filter((fv) => !isChildForm(fv))
          ?.map((fv) => {
            function getCurrentRecordPWAID(formValues) {
              return formValues.PenValues?.find(
                (pv) => pv.Pen.toString() === "1"
              )?.Values.find((v) => v.Ref.toLowerCase() === "pwaid")?.Value;
            }

            function isChildOfForm(
              parentFormValueRecord,
              childFormValueRecord
            ) {
              return (
                childFormValueRecord.ParentPWAID ===
                getCurrentRecordPWAID(parentFormValueRecord)
              );
            }

            return (
              <ListItem
                key={`${fv.FormName}-${fv.ID ?? getCurrentRecordPWAID(fv)}`}
                farmId={farmId}
                houseId={houseId}
                formValues={fv}
                childFormValues={items.filter((fv2) => isChildOfForm(fv, fv2))}
                form={forms?.find(
                  (ff) =>
                    ff.FormName.toLowerCase() === fv.FormName.toLowerCase()
                )}
                dateToday={dateToday}
              />
            );
          })}
      </List>
      {items?.length === 0 && (
        <div className="flex flex-grow flex-col items-center justify-center w-full">
          <div className="text-sm text-center italic">
            <p>No historical data found.</p>
            <p>
              Click <strong>New</strong> to add a record.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
