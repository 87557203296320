import { Alert } from "./core";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

function getStatusTheme(status) {
  switch (status) {
    case "installing":
      return "warning";
    case "installed":
      return "success";
    default:
      return "primary";
  }
}

export default function AppUpdateNotification({ onClick, status }) {
  if (isNullEmptyOrWhitespace(status)) {
    return null;
  }

  return (
    <div>
      <Alert
        theme={getStatusTheme(status)}
        size="sm"
        className="cursor-pointer"
        onClick={onClick}
      >
        {status === "installing" ? (
          <>
            <p className="text-sm font-medium">
              A new version is installing...
            </p>
            <p>The page will refresh once complete.</p>
          </>
        ) : status === "installed" ? (
          <>
            <p className="text-sm font-medium">
              A new version has been installed!
            </p>
            <p>The page will now refresh.</p>
          </>
        ) : (
          <>
            <p className="text-sm font-medium">
              A new version has been released!
            </p>
            <p>Click to update to the new release.</p>
          </>
        )}
      </Alert>
    </div>
  );
}
