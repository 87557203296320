import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Label } from "components/core";
import Addon from "./Addon";
import { ExclamationCircleIcon } from "assets/icons";
import { SelectOption } from "./SelectOption";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";
import { IListOption } from "helpers/formUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import FieldSkeleton from "./FieldSkeleton";
const ComboboxLazy = React.lazy(() => import('./Combobox'));

interface ISelectProps {
  label: string;
  id: string;
  hint?: string;
  validate?: (value: any) => boolean;
  value: any;
  setValue: (value: any) => void;
  setValid?: (
    valid: boolean,
    value: any,
    required: boolean,
    complete: boolean
  ) => void;
  required?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  labelPosition?: "top" | "left" | "inset";
  labelSize?: "large" | "small";
  addonLeft?: React.ReactNode;
  addonRight?: React.ReactNode;
  dependencies?: string[];
  defaultValue?: string;
  render?: boolean;
  placeholder?: string;
  listOptions: IListOption[];
  showSearch?: boolean;
  disableCalcTrigger?: boolean;
}

const Select = ({
  label,
  id,
  hint = undefined,
  validate = undefined,
  value,
  setValue,
  setValid = undefined,
  required = true,
  isFullWidth = false,
  disabled = false,
  labelPosition = "top",
  labelSize = undefined,
  addonLeft = undefined,
  addonRight = undefined,
  dependencies = [],
  defaultValue = undefined,
  render = true,
  placeholder = "- Select - ",
  listOptions = [],
  showSearch = false,
  disableCalcTrigger = false,
  ...other
}: ISelectProps) => {
  useCalcTrigger(value, setValue, disableCalcTrigger);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [focused, setFocused] = useState(false);

  const hasValue = value?.length > 0;
  const hasPlaceholder = placeholder?.length > 0;

  const inputErrorClasses = ["border-danger-600"];
  const inputClasses = [
    "rounded-md pt-3 pb-3",
    "border-1",
    "focus:ring-4 focus:ring-offset-0",
    `${disabled ? "disabled:opacity-50" : ""}`,
  ];
  const errorIconClasses = [];

  // Addons
  if (addonLeft) {
    inputClasses.push("pl-10");
  }
  if (addonRight) {
    inputClasses.push("pr-5");
  }

  inputClasses.push(
    "bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
  );
  errorIconClasses.push("bg-white");

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value: any) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  const validation = () => {
    if (validate) return validate(value);
  };

  //#endregion

  //#region Side-effects

  /**
   * List options have changed
   */
  useDeepCompareEffect(() => {
    if (!setInputValue) return;

    if (!touched && defaultValue && isNullEmptyOrWhitespace(value)) {
      // Ensure listOptions is not empty
      setInputValue(defaultValue);      
    } else if (touched) {
      /**
       * Clear value if touched and listOptions have changed
       */
      setInputValue("");
    } else {
      const valueExistsInList = listOptions.some(
        (option) => {        
          return option.Value?.toString().toLowerCase() === value?.toString().toLowerCase()
        }
      );

      if (!valueExistsInList) {
        setInputValue("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOptions]);

  /**
   * Trigger validation when value or dependencies change.
   * Useful when you wish to revalidate when related input changes.
   */
  useEffect(() => {
    const validationResult = validation();
    if (validationResult !== error) setError(validationResult || false);

    const complete = required && isNullEmptyOrWhitespace(value) ? false : true;
    if (setValid) setValid(!validationResult, value, required, complete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies, value, required]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div
      className={`${
        labelPosition === "left" ? "grid grid-cols-3 gap-4" : "relative"
      }`}
    >
      <Label
        id={id}
        text={label}
        required={required}
        focused={focused}
        hasValue={hasValue}
        position={labelPosition}
        size={labelSize}
        erroneous={touched && error && !focused}
        addonPosition={undefined}
        labelClasses={undefined}
        theme={undefined}
      />
      <div className={`mt-1 ${labelPosition === "left" ? "col-span-2" : ""}`}>
        <div className="rounded-md shadow-sm relative">
          {addonLeft && <Addon position="left">{addonLeft}</Addon>}
          {showSearch ? (
            <Suspense fallback={<FieldSkeleton />}>
              <ComboboxLazy 
                items={listOptions} 
                value={value ?? ""} 
                touched={touched} 
                error={error} 
                focused={focused} 
                onBlur={() => {
                  setTouched(true);
                  setFocused(false);
                }}
                onChange={(value) => setInputValue(value)}
                onFocus={() => setFocused(true)}
              />
            </Suspense>
          ) : (
            <select
              id={id}
              className={`block w-full tablet:text-sm ${inputClasses.join(" ")} ${
                touched && error && !focused ? inputErrorClasses.join(" ") : ""
              }`}
              value={value ?? ""}
              onBlur={() => {
                setTouched(true);
                setFocused(false);
              }}
              onChange={(ev) => setInputValue(ev.target.value)}
              onFocus={() => setFocused(true)}
              aria-invalid={error}
              disabled={disabled}
              {...other}
            >
              {hasPlaceholder && (
                !required ||
                !value ||
                !listOptions?.length ||
                listOptions.some(
                  (option) => {
                    if (typeof option.Value === "number") {
                      return option.Value === value;
                    }
                    
                    return option.Value?.toLowerCase() === value?.toLowerCase()
                  }
                ) === false) && (
                <SelectOption value="">{placeholder}</SelectOption>
              )}
              {listOptions?.map((option) => (
                <SelectOption
                  key={option.Id ?? option.Value}
                  value={option.Value}
                >
                  {option.Text}
                </SelectOption>
              ))}
            </select>
          )}
          {addonRight && <Addon position="right">{addonRight}</Addon>}
          {touched && error && !focused && (
            <div className="absolute -top-2 right-2 pointer-events-none bg-white px-1">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-500" />
            </div>
          )}
        </div>
        {touched && error && !focused ? (
          <p className="mt-2 text-xs text-danger-600" id="email-error">
            {error}
          </p>
        ) : (
          hint && (
            <p className="mt-2 text-xs text-gray-500" id="email-error">
              {hint}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export { Select };
