import classNames from "classnames";

interface ITooltipProps {
  children: React.ReactNode;
  color?: string;
  [key: string]: any;
}

export function Tooltip({ color, children, className, ...rest }: ITooltipProps) {
  return (
    <div
      {...rest}
      className={classNames("rounded text-sm flex flex-col", className)}
      style={{
        background: "white",
        padding: "9px 12px",
        borderLeft: "5px solid",
        borderColor: color,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {children}
    </div>
  );
}

interface ITooltipItemProps {
  label: string;
  value: React.ReactNode;
  [key: string]: any;
}

export function TooltipItem({ label, value, ...rest }: ITooltipItemProps) {
  return (
    <div className="grid grid-cols-2 gap-4" {...rest}>
      <div className="">{label}:</div>
      <div className="font-medium">{value}</div>
    </div>
  );
}
