export interface IChartData {
  id: string;
  data: any;
  standardData: any;
}

export interface IMetric {
  id: string;
  title: string;
  dataSource?: string;
}

export interface IChartKey extends IMetric {
  type?: string;
  operands?: IChartKeyOperand[];
  events?: IChartKeyEvent[];
}

export interface IChartKeyOperand {
  color?: string;
  operator: string
  tolerance?: number;
  toleranceUnit?: string;
  values: IChartKeyOperandValue[];
}

export interface IChartKeyOperandValue {
  title: string;
  type: string;
  value: string;
}

export interface IChartKeyEvent {
  title: string;
  type: string;
  func: string;
  args: string[];
}

export interface IChart {
  id: string;
  title: string;
  showHeader: boolean;
  settings: { [key: string]: any };
  keys: IChartKey[];
  tooltipKeys: IChartKey[];
  standardKeys: IChartKey[];
  type: string;
  attrs?: Record<string, any>;
  position: number;
}

export interface IDashboard {
  id: string;
  dataSources: string;
  charts: IChart[];
  title: string;
  farmGroups: string;
  menuId: string;
  sortOrder: number;
}


export enum POSITION_DIRECTION {
  UP = "up",
  DOWN = "down",
}

export const NUMERIC_OPERATORS: Readonly<Record<string, { title: string, requiredNumOfValues: number }>> = Object.freeze({
  ">=": {
    title: "Greater than or equal to",
    requiredNumOfValues: 1,
  },
  "<=": {
    title: "Less than or equal to",
    requiredNumOfValues: 1,
  },
  ">": {
    title: "Greater than",
    requiredNumOfValues: 1,
  },
  "<": {
    title: "Less than",
    requiredNumOfValues: 1,
  },
  "between": {
    title: "Between",
    requiredNumOfValues: 2,
  },
  "==": { 
    title: "Equal to",
    requiredNumOfValues: 1,
  },
  "+": {
    title: "Add",
    requiredNumOfValues: 1,
  },
  "-": {
    title: "Subtract",
    requiredNumOfValues: 1,
  },
  "*": {
    title: "Multiply",
    requiredNumOfValues: 1,
  },
  "/": {
    title: "Divide",
    requiredNumOfValues: 1,
  },
});