import { isNullEmptyOrWhitespace } from "./stringUtilities";

export function extractParamsFromUrlPath(path) {
  const result = path.split("/").filter((p) => p !== "");

  return result;
}

/**
 * Build a URL path from a list of parameters
 * @param {import("components/Sidebar").IMenuItem|undefined}    menu  Menu item to navigate to
 * @returns {{ pathname: string, search: string }}  An object to be passed to history.push
 */
 export function buildListPageUrl(
  menu,
) {
  if (isNullEmptyOrWhitespace(menu?.Path)) {
    console.error("newUrl is null, undefined or empty");
    return null;
  }

  if (menu?.AppendQueryString) {
    return redirectWithExistingSearchParams(menu?.Path);
  }

  return { pathname: menu?.Path };
}

/**
 *
 * @param {import("components/Sidebar").IMenuItem|undefined} menu   Menu item to navigate to
 * @param {string} formId
 * @param {string|undefined} parentId
 * @param {string|undefined} parentFormId
 * @param {string|number|undefined} recordId
 * @param {string|undefined} view
 * @param {{farmId: string|undefined, houseId: string|undefined}} urlParams
 * @returns {{ pathname: string, search: string }}  An object to be passed to history.push
 */
export function buildFormPageUrl(
  menu,
  formId,
  parentId = undefined,
  parentFormId = undefined,
  recordId = undefined,
  view = undefined,
  urlParams = {
    farmId: undefined,
    houseId: undefined,
  }
) {
  if (isNullEmptyOrWhitespace(menu?.Path)) {
    console.error("newUrl is null, undefined or empty");
    return null;
  }
  
  // Build URL parts
  // Ordering is important here
  const urlParts = {};
  urlParts.path = menu?.Path;
  urlParts.formId = formId?.toLowerCase();
  urlParts.view =
    view !== undefined
      ? view?.toLowerCase()
      : !isNullEmptyOrWhitespace(recordId)
      ? "edit"
      : "new";
  urlParts.id = recordId;

  if (!isNullEmptyOrWhitespace(parentId)) {
    urlParts.parentFormId = parentFormId?.toString()?.toLowerCase();
    urlParts.parentId = parentId?.toString()?.toLowerCase();

    if (isNullEmptyOrWhitespace(urlParts.parentFormId)) {
      throw new Error("When parentId is set, parentFormId must NOT be null");
    }
  }
  const pathname = `${Object.values(urlParts).filter(Boolean).join("/")}`

  // Build search params+
  const currentLocation = window?.location;
  if (currentLocation === undefined) {
    console.error("Cannot append url search params. 'currentLocation' is undefined");
    return {
      pathname
    };
  }

  const searchParams = new URLSearchParams(currentLocation.search.substring(1));
  if (urlParams.farmId !== undefined)
    searchParams.set("farmId", urlParams.farmId.toLowerCase());
  if (urlParams.houseId !== undefined)
    searchParams.set("houseId", urlParams.houseId.toLowerCase());

  return {
    pathname,
    search: "?" + searchParams.toString(),
  };
}

/**
 *
 * @param {string} newURL
 * @returns
 */
function redirectWithExistingSearchParams(newURL) {
  const currentLocation = window?.location;
  if (currentLocation === undefined) {
    console.error("Cannot append url search params. 'currentLocation' is undefined");
    return {
      pathname: newURL
    };
  }

  const url = new URL(newURL, window.location.href);

  const _searchParams = new URLSearchParams(
    currentLocation.search.substring(1)
  );
  for (const [key, value] of url.searchParams) {
    _searchParams.set(key, value);
  }

  return {
    pathname: url.pathname,
    search: "?" + _searchParams.toString(),
  };
}
