import { useActiveMenu } from "hooks/useActiveMenu";
import SchedulePage from "pages/SchedulePage";
import DashboardListPage from "pages/dashboards";
import ReportFormPage from "pages/ReportForm";
import DashboardBuilderListPage from "pages/dashboards/builder/ListPage";
import AdminNonConformanceList from "pages/admin/Audit/NonConformance/ListPage";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import ListPage from "pages/farms/ListPage";
import MediaListPage from "pages/media/ListPage";

export function FormListPage() {
  const { activeMenu } = useActiveMenu();
  const activeMenuView = activeMenu?.View?.toLowerCase();

  if (activeMenuView === "productionschedule") {
    return <SchedulePage />;
  }

  if (activeMenuView === "dashboardbuilder") {
    return <DashboardBuilderListPage />;
  }

  if (activeMenuView === "dashboardlist") {
    return <DashboardListPage />;
  }

  // if (activeView === "adminauditlist") {
  //   return <AdminAuditListPage />;
  // }

  if (activeMenuView === "adminnonconformancelist") {
    return <AdminNonConformanceList />;
  }

  if (activeMenuView === "reportform") {
    return <ReportFormPage />;
  }

  if (activeMenuView === "medialist") {
    return <MediaListPage />;
  }

  if (activeMenuView === undefined) {
    // Page not yet loaded
    return <ListSkeleton />;
  }

  return <ListPage />;
}
