import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon, /*CogIcon*/ } from "assets/icons";
import Logo from "assets/Logo";
import classNames from "classnames";
import Slideover from "./core/Slideover";
import { useMenus, useUser } from "context/AppDataProvider";
import { buildListPageUrl } from "helpers/redirectUtilities";
import { generateInitialsFromText } from "helpers/stringUtilities";
import { InitialsAvatar } from "./core";
import { useSidebar } from "context/SidebarProvider";
import { useActiveMenu } from "hooks/useActiveMenu";

export interface ISidebarProps {
  position?: "left" | "right";
  theme?: "light" | "dark";
  setTheme?: (theme: "light" | "dark") => void;
  setShowSettings: (showSettings: boolean) => void;
}

export interface IMenuItem {
  ID: string;
  Title: string;
  Module: string;
  ModuleFeatureGroup: string;
  Path: string;
  Level: string;
  AppendQueryString: false;
  Metadata: object;
  Children?: IMenuItem[];
  ParentID?: string;
  Parent?: IMenuItem;
  View: string;
}

export default function Sidebar(props: ISidebarProps) {
  const { offline, user } = useUser();
  const { sidebarOpen, setSidebarOpen } = useSidebar();
  const { menus } = useMenus();
  const { activeMenu } = useActiveMenu();

  const isActiveMenu = (menu: IMenuItem) =>
    menu.ID === activeMenu?.ID || menu.ID === activeMenu?.ParentID;

  // const handleShowSettings = () => {
  //   props.setShowSettings(true);
  //   setSidebarOpen(false);
  // };

  return (
    <Slideover
      title={
        <Logo className="mt-4 dark:text-white" style={{ height: "44px" }} />
      }
      className={classNames(props.theme, "text-white z-50")}
      open={sidebarOpen}
      setOpen={setSidebarOpen}
      position={props.position}
    >
      <div
        data-cy="sidebar"
        className={classNames("flex flex-col flex-grow overflow-y-auto")}
      >
        <div className="flex-grow flex flex-col">
          <nav className="flex-1" aria-label="Sidebar">
            {menus.map((parentMenu: IMenuItem, index) => {
              const children = parentMenu.Children || [];

              if (children.length === 1) {
                const menuItem = children[0];
                return (
                  <Link
                    data-cy="menu-open-button"
                    key={`${parentMenu.ID}_${menuItem.ID}`}
                    to={buildListPageUrl(menuItem)}
                    onClick={() => setSidebarOpen(false)}
                    className={classNames(
                      isActiveMenu(menuItem)
                        ? "text-primary"
                        : "text-white dark:text-gray-300",
                      "w-full flex items-center pl-4 py-3 hover:text-primary dark:hover:text-primary"
                    )}
                  >
                    {parentMenu.Title === menuItem.Title
                      ? menuItem.Title
                      : `${parentMenu.Title} ${menuItem.Title}`}
                  </Link>
                );
              }

              return (
                <Disclosure
                  as="div"
                  key={parentMenu.ID}
                  defaultOpen={isActiveMenu(parentMenu)}
                >
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        data-cy="menu-item"
                        className={classNames(
                          isActiveMenu(parentMenu)
                            ? "text-primary"
                            : "text-white dark:text-gray-300",
                          "group w-full flex items-center pl-4 pr-1 py-3 text-left outline-none hover:text-primary"
                        )}
                      >
                        <span className="flex-1">{parentMenu.Title}</span>
                        <ChevronRightIcon
                          className={`mr-1 ml-2 h-5 w-5 ${
                            !open ? "rotate-0" : "rotate-90"
                          }`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="py-2">
                        {children.map((menuItem) => (
                          <Disclosure.Button
                            data-cy="menu-item"
                            key={`${parentMenu.ID}_${menuItem.ID}`}
                            as={Link}
                            to={buildListPageUrl(menuItem)}
                            onClick={() => setSidebarOpen(false)}
                            className={classNames(
                              isActiveMenu(menuItem)
                                ? "text-primary"
                                : "text-white dark:text-gray-300",
                              "group w-full flex items-center pl-8 pr-2 py-2 text-sm hover:text-primary dark:hover:text-primary"
                            )}
                          >
                            {menuItem.Title}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="shrink-0 flex border-t border-black/20 bg-black/10 dark:border-gray-900 dark:bg-gray-800 dark:bg-black/20 p-4">
        <div className="shrink-0 w-full group block">
          <div className="flex items-center">
            <div>
              <button
                className={`flex text-sm rounded-full outline-none ring-2 ring-offset-1 ring-offset-primary-darkest2 dark:ring-offset-gray-800 relative ${
                  offline ? "ring-gray-500" : "ring-success-500"
                }`}
                id="user-profile"
              >
                {user && (
                  <InitialsAvatar
                    initials={generateInitialsFromText(
                      `${(user as any).FullName}`
                    )}
                  />
                )}
                <span className="flex h-3 w-3 absolute -right-1 bottom-0">
                  {!offline && (
                    <span
                      className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-success-500`}
                    ></span>
                  )}
                  <span
                    className={`relative inline-flex rounded-full h-3 w-3 ${
                      offline ? "bg-gray-500" : "bg-success-500"
                    } ring-1 ring-primary-darkest2 dark:ring-gray-800`}
                  ></span>
                </span>
              </button>
            </div>
            <div className="ml-4 flex-grow">
              {(user as any)?.FullName ? (
                <>
                  <p className="text-sm font-medium">
                    {(user as any).FullName}
                  </p>
                  <p className="text-xs">{(user as any).UserName}</p>
                </>
              ) : null}
            </div>
            {/* <div
              className="cursor-pointer dark:text-gray-300 hover:text-primary dark:hover:text-primary"
              onClick={() => handleShowSettings()}
            >
              <div className="sr-only">Settings</div>
              <CogIcon className="h-5 w-5" />
            </div> */}
          </div>
        </div>
      </div>
    </Slideover>
  );
}
