import classNames from "classnames";
import Card, { CardTitle } from "components/Card";
import React from "react";

const Widget = ({
  title,
  showTitle,
  children,
  className = "",
  size = "",
}) => {
  function getWidgetSizeClass() {
    switch (size) {
      case "small":
        return "col-span-12 laptop:col-span-3";
      case "medium":
        return "col-span-12 laptop:col-span-4";
      case "large":
        return "col-span-12 laptop:col-span-6";
      case "xlarge":
        return "col-span-12 laptop:col-span-9";
      default:
        return "col-span-12";
    }
  }

  const _showHeader = showTitle && title;

  return (
    <div className={classNames(className, "relative", getWidgetSizeClass())}>
      <Card className="min-h-full flex flex-col">
        {_showHeader && (
          <CardTitle>{title}</CardTitle>
        )}
        <div className="flex-grow">{children}</div>
      </Card>
    </div>
  );
};

export default Widget;
