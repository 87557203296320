import equal from "fast-deep-equal/es6/react";
import { isNumeric } from "./stringUtilities";

export function isEqual(a, b) {
  // Objects - deep compare
  if (typeof a == "object" && typeof b == "object") {
    return equal(a, b);
  }
  
  // Strings and numbers
  return compareStringOrNumber(a, b) === 0;
}

export function compareStringOrNumber(a, b) {
  if (isNumeric(a) && isNumeric(b)) {
    return a - b;
  }

  return String(a).localeCompare(String(b));
}