import {
  dateAdd,
  dateDiffInMilliseconds,
  dateSubtract,
  localDate,
  localDateFromSQL,
  localDateToSQL,
  startOfFromDate,
} from "./dateUtilities";
import {
  calcCoefficiencyVariance,
  calcAverage,
  calcStandardDeviation,
} from "./mathUtilities";
import { matchSorter } from "match-sorter";

/**
 *
 * @param {string} text
 * @returns
 */
export function generateInitialsFromText(text) {
  const initials = text
    .split(" ")
    .map((word) => {
      word = word.trim();
      return word.substring(0, 1);
    })
    .join("")
    .substring(0, 3);

  return initials;
}

/**
 * A string literal to format metatext.
 * @param   {strings[]} strings Array of string values.
 * @param   {any[]}     values  Array of values.
 * @returns {string}    Formatted string.
 */
export function metaText(strings, ...values) {
  return values.reduce((result, value, index) => {
    return (
      <>
        {result}
        <span className="italic">{value}</span>
        {strings[index + 1]}
      </>
    );
  }, strings[0]);
}

/**
 * Parse string as string literal. Expected format `${regex([a-z]+):Value can only contain characters a-z, case insensitive.}`
 * @param {string} str  - String to parse for validation and message.
 * @returns {[{ function: string, arguments: string, message: string }]} Array of objects containing regex pattern and message.
 * @example
 *  parseValidationString("${regex([a-z]+):Value can only contain characters a-z, case insensitive.}")
 * @
 */
export function parseValidationString(str) {
  // TODO: update regex, use of colon in regex or message will break the syntax
  const regex = /\${([a-z]+)\(([^()]+)\):([^{}]+)}/gi;
  let matches;
  const results = [];

  while ((matches = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (matches.length !== 4)
      throw new Error(
        // eslint-disable-next-line no-template-curly-in-string
        "Validation format must be ${function(arguments):message}"
      );

    results.push({
      function: matches[1],
      arguments: matches[2],
      message: matches[3],
    });
  }

  return results;
}

/**
 * Parses a string converting field references to values.
 * @param {string} str  String or stringified JSON object.
 * @returns {string}  Returns a calculation string with values replacing references.
 */
export function parseCalculationString(
  str,
  formValues,
  prevFormValues,
  standards,
  farm,
  house,
  penId = "1",
  birdAge,
  formDate = null
) {
  if (str === undefined || str === null || str === "" || typeof str != "string")
    return str;

  const regex = /\${([^{}]+)}/gi;
  const replacements = [];
  let matches;

  function addReplacement(replacement) {
    replacements.push(replacement);
    return;
  }

  /**
   * Get the previous character to the found match that is NOT a whitespace character.
   * @param {number} index - Position of beginning of found match
   * @returns {string}  Found character
   */
  function getPrevCharacter(index) {
    let i = index;
    do {
      i -= 1;
      const char = str.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i > 0);
  }

  /**
   * Get the previous character to the found match that is NOT a whitespace character.
   * @param {number} index - Position of beginning of found match
   * @returns {string}  Found character
   */
  function getNextCharacter(index) {
    let i = index;
    do {
      i += 1;
      const char = str.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i <= str.length);
  }

  function normaliseValue(value, prevChar, nextChar) {
    value = !isNaN(value) ? Number(value) : value; // All values should be numbers?

    if (value === undefined || value === null || value === "" || value === 0)
      return ["/", "*"].includes(prevChar) || ["*"].includes(nextChar) ? 1 : 0;

    return value;
  }

  while ((matches = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    const prevChar = getPrevCharacter(matches.index);
    const nextChar = getNextCharacter(regex.lastIndex - 1);

    matches.forEach((m, i) => {
      if (i !== 0) {
        // First match should be ignored

        // parse variable ref
        const parsedVariableRef = parseVariableRef(m);
        // Uncomment to debug
        // console.log(str, parsedVariableRef, formValues, prevFormValues)
        if (parsedVariableRef.lookup === "standards" && !isNull(standards)) {
          // standards:1
          const pen = house.Pens.find((p) => p.PenNumber === penId);
          if (penId.toString() !== "1" && !pen?.Placement?.BirdType) {
            // Placement is null, default to Pen 1 values
            const pen1 = house.Pens.find((p) => p.PenNumber === 1);
            if (!!pen1?.Placement?.BirdType) {
              pen.Placement = {
                BirdType: pen1.Placement.BirdType,
                BirdSex: pen1.Placement.BirdSex,
              };
            }
          }
          const result =
            standards.find(
              (s) =>
                s.ID === parsedVariableRef.ref &&
                s.FarmGroup === farm.FarmGroup &&
                s.BirdType === pen?.Placement?.BirdType &&
                s.BirdSex === pen?.Placement?.BirdSex &&
                s.Days === birdAge
            )?.Value ?? 0;

          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (
          parsedVariableRef.lookup === "yesterday" &&
          !isNull(prevFormValues)
        ) {
          // prevValues:1

          const penFormValues = prevFormValues?.find(
            (p) => p.Pen.toString() === penId.toString()
          );
          const prevFormValue = penFormValues?.Values?.find(
            (fv) => fv.Ref.toLowerCase() === parsedVariableRef.ref.toLowerCase()
          );
          const result = !isNull(prevFormValue)
            ? prevFormValue[parsedVariableRef.refProperty]
            : null;

          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (parsedVariableRef.lookup === "house") {
          // house:birdsalive
          if (
            parsedVariableRef.ref.toLowerCase() === "birdsalive" &&
            !isNull(formValues)
          ) {
            // Birds alive
            const birdsalive =
              formValues?.reduce(
                (result, pen) =>
                  (result += parseInt(pen?.BirdsAlive?.BirdsAlive ?? 0)),
                0
              ) ?? 0;

            // Total dead
            const totaldead =
              formValues?.reduce((result, pen) => {
                const _totaldead =
                  pen.Values.find((v) => v.Ref.toLowerCase() === "totaldead")
                    ?.Value ?? 0;

                return (result += parseInt(_totaldead));
              }, 0) ?? 0;

            // Birds removed
            const birdsRemoved =
              formValues.reduce((result, pen) => {
                const _malesRemoved =
                  pen.Values.find(
                    (v) => v.Ref.toLowerCase() === "totalmaleremoved"
                  )?.Value ?? 0;

                const _femalesRemoved =
                  pen.Values.find(
                    (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
                  )?.Value ?? 0;

                return (result +=
                  parseInt(_malesRemoved) + parseInt(_femalesRemoved));
              }, 0) ?? 0;

            addReplacement(
              normaliseValue(
                birdsalive - totaldead - birdsRemoved,
                prevChar,
                nextChar
              )
            );
          }
        } else if (parsedVariableRef.lookup === "pen" && !isNull(formValues)) {
          if (parsedVariableRef.ref.toLowerCase() === "birdsalive") {
            // pen:birdsalive
            const penFormValues = formValues?.find(
              (p) => p.Pen.toString() === penId.toString()
            );
            const birdsalive = parseInt(
              penFormValues?.BirdsAlive?.BirdsAlive ?? 0
            );

            // Total dead
            const totaldead = parseInt(
              penFormValues?.Values?.find(
                (fv) => fv.Ref.toLowerCase() === "totaldead"
              )?.Value ?? 0
            );

            // Birds removed
            const malesRemoved = parseInt(
              penFormValues?.Values?.find(
                (v) => v.Ref.toLowerCase() === "totalmaleremoved"
              )?.Value ?? 0
            );

            const femalesRemoved = parseInt(
              penFormValues?.Values?.find(
                (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
              )?.Value ?? 0
            );

            addReplacement(
              normaliseValue(
                birdsalive - totaldead - malesRemoved - femalesRemoved,
                "",
                ""
              )
            );
          } else if (
            parsedVariableRef.ref.toLowerCase() === "birdsalive:female" &&
            !isNull(formValues)
          ) {
            // pen:birdsalive:female
            const penFormValues = formValues?.find(
              (p) => p.Pen.toString() === penId.toString()
            );
            const birdsalive = parseInt(
              penFormValues?.BirdsAlive?.FemaleAlive ?? 0
            );

            // Total dead
            const totaldead = parseInt(
              penFormValues?.Values?.find(
                (fv) => fv.Ref.toLowerCase() === "totaldeadfemale"
              )?.Value ?? 0
            );

            // Birds removed
            const femalesRemoved = parseInt(
              penFormValues?.Values?.find(
                (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
              )?.Value ?? 0
            );

            addReplacement(
              normaliseValue(
                birdsalive - totaldead - femalesRemoved,
                prevChar,
                nextChar
              )
            );
          } else if (
            parsedVariableRef.ref.toLowerCase() === "birdsalive:male" &&
            !isNull(formValues)
          ) {
            // pen:birdsalive:male
            const penFormValues = formValues?.find(
              (p) => p.Pen.toString() === penId.toString()
            );
            const birdsalive = parseInt(
              penFormValues?.BirdsAlive?.MaleAlive ?? 0
            );

            // Total dead
            const totaldead = parseInt(
              penFormValues?.Values?.find(
                (fv) => fv.Ref.toLowerCase() === "totaldeadmale"
              )?.Value ?? 0
            );

            // Birds removed
            const malesRemoved = parseInt(
              penFormValues?.Values?.find(
                (v) => v.Ref.toLowerCase() === "totalmaleremoved"
              )?.Value ?? 0
            );

            addReplacement(
              normaliseValue(
                birdsalive - totaldead - malesRemoved,
                prevChar,
                nextChar
              )
            );
          } else {
            // TODO just ignores other pen: references, we should handle this scenario better.
            addReplacement(normaliseValue(null, prevChar, nextChar));
          }
        } else if (parsedVariableRef.lookup === "date") {
          if (
            parsedVariableRef.ref.toLowerCase().startsWith("this") &&
            !isNullEmptyOrWhitespace(formDate?.toString())
          ) {
            // date:this

            function parseSubFunction(str) {
              const regex = new RegExp(
                "([+-])([0-9]+)(hours|days|weeks|months|years)",
                "gm"
              );

              return regex.exec(str);
            }

            let _result = formDate;

            if (parsedVariableRef.ref.indexOf(":") > -1) {
              const _subFunc = parseSubFunction(parsedVariableRef.ref);

              if (_subFunc?.length === 4) {
                if (_subFunc[1] === "+") {
                  _result = dateAdd(_result, _subFunc[2], _subFunc[3]);
                } else if (_subFunc.length === 4 && _subFunc[1] === "-") {
                  _result = dateSubtract(_result, _subFunc[2], _subFunc[3]);
                }
              }
            }
            addReplacement(localDateToSQL(_result, { includeOffset: false }));
          } else if (parsedVariableRef.ref.toLowerCase() === "today") {
            // date:today
            addReplacement(
              localDateToSQL(localDate(), { includeOffset: false })
            );
          }
        } else if (parsedVariableRef.lookup === "cv" && !isNull(formValues)) {
          // Coefficient of Variance. E.g. ${cv:V1,V2,V3}
          const refs = parsedVariableRef.ref.split(",");

          const penFormValues = formValues?.find(
            (fv) => fv.Pen.toString() === penId.toString()
          );

          const refValues = [];
          for (const v of refs) {
            const formValue = penFormValues?.Values?.find(
              (fv) => fv.Ref.toLowerCase() === v.toLowerCase()
            );
            if (formValue?.Value) refValues.push(formValue.Value);
          }

          const result = calcCoefficiencyVariance(
            refValues.filter((v) => !isNullEmptyOrWhitespace(v))
          );

          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (parsedVariableRef.lookup === "avg" && !isNull(formValues)) {
          // Average. E.g. ${avg:V1,V2,V3}

          const refs = parsedVariableRef.ref.split(",");

          const penFormValues = formValues?.find(
            (fv) => fv.Pen.toString() === penId.toString()
          );

          const refValues = [];
          for (const v of refs) {
            const formValue = penFormValues?.Values?.find(
              (fv) => fv.Ref.toLowerCase() === v.toLowerCase()
            );
            if (formValue?.Value) refValues.push(formValue.Value);
          }

          const result = calcAverage(
            refValues.filter((v) => !isNullEmptyOrWhitespace(v))
          );

          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (parsedVariableRef.lookup === "sd" && !isNull(formValues)) {
          // Standard Deviation. E.g. ${sd:V1,V2,V3}

          const refs = parsedVariableRef.ref.split(",");

          const penFormValues = formValues?.find(
            (fv) => fv.Pen.toString() === penId.toString()
          );

          const refValues = [];
          for (const v of refs) {
            const formValue = penFormValues?.Values?.find(
              (fv) => fv.Ref.toLowerCase() === v.toLowerCase()
            );
            if (formValue?.Value) refValues.push(formValue.Value);
          }

          const result = calcStandardDeviation(
            refValues.filter((v) => !isNullEmptyOrWhitespace(v))
          );

          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (
          parsedVariableRef.lookup === "datediff" &&
          !isNull(formValues)
        ) {
          // Date/Time difference. E.g. ${datediff:V1,V2,V3}
          let result;

          const refs = parsedVariableRef.ref.split(",");

          const penFormValues = formValues?.find(
            (fv) => fv.Pen.toString() === penId.toString()
          );

          const refValues = [];
          for (const v of refs) {
            const formValue = penFormValues?.Values?.find(
              (fv) => fv.Ref.toLowerCase() === v.toLowerCase()
            );
            if (formValue?.Value) refValues.push(formValue.Value);
          }

          if (refValues.length === 2) {
            let date1 = refValues[0];
            let date2 = refValues[1];
            const startOfCurrentDateTime = startOfFromDate(localDate(), "day");

            // Handle time fields
            if (isTimeString(date1)) {
              // Is time only, e.g. 04:00
              // Convert to date using current day
              const extractedTime = extractHoursMinutesFromTimeString(date1);

              date1 = dateAdd(
                startOfCurrentDateTime,
                extractedTime.hours,
                "hours"
              );
              date1 = dateAdd(date1, extractedTime.minutes, "minutes");
            } else {
              date1 = localDateFromSQL(date1);
            }
            if (isTimeString(date2)) {
              // Is time only, e.g. 04:00
              // Convert to date using current day
              const extractedTime = extractHoursMinutesFromTimeString(date2);
              date2 = dateAdd(
                startOfCurrentDateTime,
                extractedTime.hours,
                "hours"
              );
              date2 = dateAdd(date2, extractedTime.minutes, "minutes");
            } else {
              date2 = localDateFromSQL(date2);
            }

            result = dateDiffInMilliseconds(date1, date2);
          }
          addReplacement(normaliseValue(result, prevChar, nextChar));
        } else if (!isNull(formValues)) {
          // values:1
          const penFormValues = formValues?.find(
            (fv) => fv.Pen.toString() === penId.toString()
          );
          const formValue = penFormValues?.Values?.find(
            (fv) => fv.Ref.toLowerCase() === parsedVariableRef.ref.toLowerCase()
          );
          const result = !isNull(formValue)
            ? formValue[parsedVariableRef.refProperty]
            : null;

          addReplacement(normaliseValue(result, prevChar, nextChar));
        }
      }
    });

    function extractHoursMinutesFromTimeString(date) {
      if (isNullEmptyOrWhitespace(date)) return;
      const hours = date?.slice(0, 2);
      const minutes = date?.slice(3, 5);

      return { hours, minutes };
    }

    function isTimeString(date) {
      return date.length === 5;
    }
  }

  const result = str.replace(regex, () => replacements.shift());
  // if (str.toLowerCase().startsWith("${sd:")) {
  //   console.log(str, formValues, replacements, result)
  // }
  return result;
}

/**
 *
 * @param {string} str
 * @example
 * parseVariableRef("V1") -> { ref: "V1", lookup: "values" }
 * parseVariableRef("V1|QG1") -> { ref: "V1", lookup: "values", questionGroup: "QG1" }
 * parseVariableRef("yesterday:1") -> { ref: "1", lookup: "yesterday" }
 * parseVariableRef("standards:5") -> { ref: "5", lookup: "standards" }
 * parseVariableRef("pen:birdsalive:male") -> { ref: "5", lookup: "pen" }
 */
function parseVariableRef(str) {
  // Split string by colon(s)
  const nameValue = str.split(":");
  // Lookup name is value found before the first colon
  // Extract lookup from array
  const lookup = nameValue.length > 1 ? nameValue.splice(0, 1)[0] : "values";
  // Join remaining array to form ref,
  // then split to find ref property.
  const refSplit = nameValue.join(":").split(".");
  // Ref property is value immediately after first occurence of 'dot'.
  const refProperty = refSplit[1] ?? "Value";

  const questionGroupSplit = refSplit[0].split("|");
  const questionGroup = questionGroupSplit[1];
  const ref = questionGroupSplit[0] ?? null;

  return { ref, refProperty, lookup, questionGroup };
}

/**
 * Convert string w/o flags to regex.
 * @param {*} str
 * @returns
 */
export function convertStringToRegex(str) {
  const index = str.lastIndexOf("/");
  if (index < 0) {
    return new RegExp(str);
  } else {
    return new RegExp(str.slice(1, index), str.slice(index + 1));
  }
}

/**
 * True/false string is a valid number
 * @param {String} str
 * @returns
 */
export function isNumeric(str) {
  if (typeof str == "number") return true;
  if (typeof str != "string") return false;

  return !isNaN(str) && !isNaN(parseFloat(str));
}

/**
 * Is value undefined, null, empty or whitespace
 * @param {string|number|object|array|null|undefined} value
 * @returns True/false value is undefined, null, empty or whitespace
 */
export function isNullEmptyOrWhitespace(value) {
  if (isNull(value)) return true;

  // check if is an empty date
  if (value instanceof Date) {
    if (value.toString() === "Invalid Date") return true;
  } else if (value instanceof Object) {
    // Object
    return Object.keys(value).length === 0;
  } else if (value instanceof Array) {
    // Array
    return value.length === 0;
  }

  value = value.toString().trim();
  if (value.length === 0) return true;

  return false;
}

/**
 * Is value undefined or null
 * @param {any} value
 * @returns True/false value is undefined or null
 */
export function isNull(value) {
  if (value === undefined || value === null) return true;

  return false;
}

export function extractTextFromJSX(jsx) {
  if (isNull(jsx)) return "";
  if (typeof jsx === "string") return jsx;
  if (typeof jsx === "number") return jsx.toString();
  if (typeof jsx === "boolean") return jsx.toString();
  if (typeof jsx === "object") {
    if (jsx instanceof Array) {
      const result = jsx.reduce((acc, child) => {
        acc.push(extractTextFromJSX(child));

        return acc;
      }, []);

      return result.join(" ");
    }

    if (jsx.hasOwnProperty("props")) {
      if (jsx.props.hasOwnProperty("children")) {
        return extractTextFromJSX(jsx.props.children);
      }
    }
  }

  return "";
}

/**
 * Parse string to JSON
 * @param {string} json
 * @returns {object}
 */
export function parseJSON(json) {
  if (isNullEmptyOrWhitespace(json)) return json;

  let parsed = json;

  try {
    parsed = JSON.parse(json);
  } catch {
    // Do nothing
  }

  return parsed;
}

/**
 * Sort by relevance
 * @param {Object[]} list   Array of strings or objects to sort
 * @param {String} str      String to match
 * @param {String[]} keys   Array of keys to use for the ranking
 * @returns
 */
export function sortByRelevance(list, str, keys) {
  return matchSorter(list, str, { keys });
}

export function toReadableString(name) {
  if (isNullEmptyOrWhitespace(name)) return name;

  var words = name.match(/[A-Za-z][a-z]*|[0-9]+/g) || [];

  return words.map(capitalizeFirstChar).join(" ");
}

export function capitalizeFirstChar(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

export function isStatus(value) {
  if (isNullEmptyOrWhitespace(value)) return false;

  value = value?.toString();

  const _typicalStatusTerminology = [
    "active",
    "open",
    "inactive",
    "suspended",
    "terminated",
    "deceased",
    "deactivated",
    "deleted",
    "dormant",
    "invalid",
    "invalidated",
    "closed",
    "pass",
    "fail",
    "critical",
    "minor",
    "major",
  ];

  if (_typicalStatusTerminology.includes(value.toLowerCase())) {
    return true;
  }

  return false;
}

export function isStatusPositive(value) {
  const _typicalStatusTerminologyPositive = ["active", "closed", "pass"];

  if (_typicalStatusTerminologyPositive.includes(value.toLowerCase())) {
    return true;
  }

  return false;
}

export function isStatusNegative(value) {
  const _typicalStatusTerminologyNegative = [
    "inactive",
    "suspended",
    "terminated",
    "deceased",
    "deactivated",
    "deleted",
    "dormant",
    "invalid",
    "invalidated",
    "open",
    "fail",
    "critical",
    "minor",
    "major",
  ];

  if (_typicalStatusTerminologyNegative.includes(value.toLowerCase())) {
    return true;
  }

  return false;
}

export function convertNullOrUndefinedStringToEmptyString(value) {
  const _nullOrUndefinedPattern = /^null$|^undefined$/i;

  if (_nullOrUndefinedPattern.test(value)) {
    return "";
  }

  return value;
}

export function getDynamicTextColor(hexcolor, lightTextColor = "white", darkTextColor = "black") {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? lightTextColor : darkTextColor;
}

export function convertHexToRGBA(hex, opacity) {
  hex = hex.replace("#", "");
  var r = parseInt(hex.substr(0, 2), 16);
  var g = parseInt(hex.substr(2, 2), 16);
  var b = parseInt(hex.substr(4, 2), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export async function uuid() {
  return await import("uuid").then((uuid) => {
    return uuid.v4();
  });
}