import React, { useEffect } from "react";
import { Notification } from "./core";

export default function BackgroundSyncNotification() {
  const [showNotification, setShowNotification] = React.useState(false);
  const [theme, setTheme] = React.useState<string | undefined>(undefined);
  const [message, setMessage] = React.useState("");

  const timeout = React.useRef<NodeJS.Timeout | undefined>(undefined);  

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = (event) => {
        if (event.data && event.data.type === "SYNC_UPDATE") {
          setShowNotification(true);
          setMessage(event.data.message);
        } else if (event.data && event.data.type === "SYNC_END") {
          setMessage(event.data.message);
          setTheme("success");
        } else {
          setShowNotification(false);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (showNotification) {
      // Reset timeout if it exists
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        setShowNotification(false);
      }, 5000);
      return () => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current);
          timeout.current = undefined;
        }
      }
    }
  }, [showNotification]);

  if (!showNotification) {
    return null;
  }

  return (
    <Notification
      key="background-sync-notification"
      title="Sync"
      description={message}
      className="cursor-pointer"
      icon={undefined}
      theme={theme}
      onClose={() => setShowNotification(false)}
    />
  );
}
