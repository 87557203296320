import { useState, useEffect } from "react";

export const useTheme = () => {
  const [theme, setTheme] = useState('light');

  const onChangeTheme = (theme: string) => {
    setTheme(theme === 'dark' ? 'dark' : 'light');
    window.localStorage.setItem('theme', theme);
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    localTheme && onChangeTheme(localTheme);
  }, []);

  return [theme, onChangeTheme];
}