import { useEffect, useState } from "react";

export default function useCalcTrigger(
  value: any,
  setValue: (value: any) => void,
  disableTrigger: boolean
): void {
  const [calcTriggered, setCalcTriggered] = useState(disableTrigger ? true : false);
  
  /**
   * Set initial value to trigger calculations
   */
  useEffect(() => {
    if (calcTriggered || !setValue || !value) return;
    
    if (setValue) {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      // console.log("useCalcTrigger", setValue, value);
      setValue(value ?? "");
      setCalcTriggered(true);
    }
  }, [calcTriggered, setValue, value]);
}
