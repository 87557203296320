import { useMemo } from "react";
import { useState, useContext } from "react";
import { AppDataContext, useFarms, useUser } from "context/AppDataProvider";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import {
  filterUserDashboardsByMenuId,
  getDashboardById,
} from "helpers/dashboardUtilities";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import Tabs from "components/Tabs";
import Dashboard from "components/Dashboard";
import { useActiveMenu } from "hooks/useActiveMenu";

export default function DashboardsListPage() {
  const { dashboards } = useContext(AppDataContext);
  const { user } = useUser();
  const { farms } = useFarms();

  const { activeMenu } = useActiveMenu();

  const [selectedDashboardId, setSelectedDashboardId] = useState(undefined);

  const userDashboards = useMemo(() => {
    if (
      !dashboards?.length ||
      user?.UserName === undefined ||
      !farms?.length ||
      activeMenu?.ID === undefined
    ) {
      return undefined;
    }

    const result = filterUserDashboardsByMenuId(
      dashboards,
      user,
      farms,
      activeMenu.ID
    );

    return result;
  }, [dashboards, farms, user, activeMenu?.ID]);

  const selectedDashboard = useMemo(() => {
    if (!userDashboards?.length) {
      return undefined;
    }

    return getDashboardById(
      userDashboards,
      selectedDashboardId ?? userDashboards?.[0]?.id
    );
  }, [userDashboards, selectedDashboardId]);

  return (
    <main className="flex flex-col flex-grow overflow-x-hidden">
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {activeMenu?.Title !== undefined ? (
              activeMenu.Title
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
        </div>

        <div className="col-span-full">
          <div className="flex flex-grow flex-col">
            {userDashboards?.length > 0 ? (
              <div>
                {userDashboards?.length > 1 && (
                  <Tabs
                    className="mb-4"
                    tabs={userDashboards.map((d) => ({
                      name: d.title,
                      id: d.id,
                      current:
                        selectedDashboardId !== undefined
                          ? selectedDashboardId === d.id
                          : userDashboards?.[0]?.id === d.id,
                    }))}
                    onChange={(id) => setSelectedDashboardId(id)}
                  ></Tabs>
                )}
                <Dashboard key={`dashboard-${selectedDashboard.id}`} dashboard={selectedDashboard} />
              </div>
            ) : (
              <ListSkeleton />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
