import { ChevronRightIcon } from "assets/icons";
import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import ButtonOptions, { IOption } from "./ButtonOptions";
import classNames from "classnames";

export interface IButtonProps {
  isFullWidth?: boolean;
  theme?:
    | "text"
    | "primary"
    | "tertiary"
    | "danger"
    | "success"
    | "warning"
    | "gray"
    | undefined;
  size?: "small" | "medium" | "large";
  shape?: "pill" | "rounded";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  type?: "button" | "submit" | "reset";
  options?: IOption[];
  optionsContainerPosition?: "top" | "left" | "bottom";
  optionsContainerClassName?: string;
  optionsClassName?: string;
  optionsStyles?: React.CSSProperties | undefined;
  optionsHeading?: string;
  optionProps?: any;
  showOptions?: boolean;
  onShowOptions?: () => void;
  onHideOptions?: () => void;
  showStats?: boolean;
  showSearch?: boolean;
  optionsFilteredByMeta?: Record<string, any>;
  showMoreOptionsIcon?: boolean;
}

const Button = ({
  isFullWidth = false,
  theme = undefined,
  size = undefined,
  onClick = undefined,
  label = undefined,
  children,
  className = "",
  shape = "rounded",
  disabled = false,
  icon = undefined,
  iconPosition = "left",
  type = "button",
  options = undefined,
  optionsContainerPosition = "top",
  optionsContainerClassName = "",
  optionsClassName = "",
  optionsStyles = undefined,
  optionsHeading = undefined,
  optionProps = {},
  showOptions = false,
  onShowOptions = undefined,
  onHideOptions = undefined,
  showStats = false,
  showSearch = false,
  optionsFilteredByMeta = undefined,
  showMoreOptionsIcon = true,
  ...other
}: IButtonProps) => {
  const [show, setShow] = useState(showOptions);
  const [buttonElement, setButtonElement] = useState<any>(undefined);

  //#region Callbacks

  /**
   * Handle button click
   * @param {Event} ev
   */
  const handleOnClick = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!isNullEmptyOrWhitespace(options)) {
      let result;
      setShow((prevState) => {
        result = !prevState;

        if (result) {
          if (!!onShowOptions) onShowOptions();
        } else {
          if (!!onHideOptions) onHideOptions();
        }

        return result;
      });
    }

    !!onClick && onClick(ev);
  };

  //#endregion

  //#region Side-effects

  /**
   * Toggle setShow when showOptions changes
   */
  useEffect(() => {
    if (isNullEmptyOrWhitespace(options)) return;

    setShow((prevState) =>
      prevState !== showOptions ? showOptions : prevState
    );
  }, [showOptions, options]);

  //#endregion

  //#region helpers

  // Default styles
  const classArr = [
    "inline-flex items-center justify-start",
  ];

  // Addition classes
  if (className) {
    classArr.push(className);
  }

  // Width
  if (isFullWidth) classArr.push("w-full");

  // Size
  if (theme !== "text") {
    if (size === "small") {
      if (shape === "pill") {
        classArr.push("py-2 px-2", "text-xs");
      } else {
        classArr.push("py-1 px-2", "text-xs");
      }
    } else if (size === "large") {
      if (shape === "pill") {
        classArr.push("py-4 px-4", "text-lg");
      } else {
        classArr.push("py-4 px-8", "text-lg");
      }
    } else {
      if (shape === "pill") {
        classArr.push("py-3 px-3", "text-sm");
      } else {
        classArr.push("py-2 px-4");
      }
    }
  }

  // Style
  if (theme === "primary") {
    classArr.push(
      "shadow-sm uppercase",
      "bg-primary bg-gradient-to-r from-primary to-secondary border-primary hover:bg-primary-dark text-white",
      "focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter"
    );
  } else if (theme === "success") {
    classArr.push(
      "shadow-sm uppercase",
      "bg-success-50 bg-gradient-to-r from-success-50 via-success-50 to-success-100 border border-success-400 shadow-sm font-medium text-success-600",
      "hover:bg-success-50",
      "focus:border-success-500 focus:text-success-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-success-500 focus:ring-opacity-10"
    );
  } else if (theme === "warning") {
    classArr.push(
      "shadow-sm uppercase",
      "bg-warning-50 bg-gradient-to-r from-warning-50 via-warning-50 to-warning-100 border border-warning-400 shadow-sm font-medium text-warning-600",
      "hover:bg-warning-50",
      "focus:border-warning-500 focus:text-warning-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-warning-500 focus:ring-opacity-10"
    );
  } else if (theme === "danger") {
    classArr.push(
      "shadow-sm uppercase",
      "bg-danger-50 bg-gradient-to-r from-danger-50 via-danger-50 to-danger-100 border border-danger-400 shadow-sm font-medium text-danger-600",
      "hover:bg-danger-50",
      "focus:border-danger-500 focus:text-danger-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-danger-500 focus:ring-opacity-10"
    );
  } else if (theme === "gray") {
    classArr.push(
      "shadow-sm uppercase",
      "bg-gray-100 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 border border-gray-400 shadow-sm font-medium text-gray-600",
      "hover:bg-gray-100 hover:bg-none",
      "focus:border-gray-500 focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-gray-500 focus:ring-opacity-10"
    );
  } else if (theme === "text") {
    classArr.push("font-medium hover:text-primary-dark text-primary focus:outline-none");
  } else {
    classArr.push(
      "uppercase",
      "border border-primary font-medium text-primary",
      "hover:bg-primary hover:text-white",
      "focus:border-primary focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10"
    );
  }

  // Shape
  if (shape === "pill") {
    classArr.push("rounded-full");
  } else {
    classArr.push("rounded-sm");
  }

  // Disabled
  if (disabled) {
    classArr.push("opacity-75 cursor-not-allowed");
  }

  const WrapperComponent =
    !isNullEmptyOrWhitespace(options) ||
    !isNullEmptyOrWhitespace(optionsContainerClassName)
      ? "div"
      : Fragment;

  const WrapperComponentProps =
    WrapperComponent === "div"
      ? {
          className: classNames(
            optionsContainerClassName,
            !isNullEmptyOrWhitespace(options)
              ? "relative inline-block text-left"
              : null
          ),
        }
      : {};

  const rootElement = document.querySelector("#root");

  return (
    <WrapperComponent {...WrapperComponentProps}>
      <button
        {...other}
        type={type}
        className={classArr.join(" ")}
        onClick={(ev) => handleOnClick(ev)}
        disabled={disabled}
        ref={setButtonElement}
        data-cy="button"
      >
        {iconPosition !== "right" && icon}
        {children}
        {showMoreOptionsIcon && options?.length ? (
          <ChevronRightIcon
            className={`-mr-1 ml-2 h-5 w-5 transition-all duration-500 ease-in-out ${
              !show ? "rotate-0 text-gray-400" : "rotate-90 text-primary"
            }`}
          />
        ): null}
        {iconPosition === "right" && icon}
      </button>

      {options !== undefined &&
        show &&
        rootElement &&
        ReactDOM.createPortal(
          <ButtonOptions
            className={optionsClassName}
            style={optionsStyles}
            buttonElement={buttonElement}
            title={optionsHeading}
            filters={optionsFilteredByMeta}
            setShow={setShow}
            onHideOptions={onHideOptions}
            showStats={showStats}
            showSearch={showSearch}
            options={options}
            optionProps={optionProps}
          />,
          rootElement
        )}
    </WrapperComponent>
  );
};

export { Button };
