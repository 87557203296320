import React, { useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import useCalcTrigger from "hooks/useCalcTrigger";

const Html = ({
  id,
  label,
  value,
  defaultValue,
  setValue,
  render,
  className,
  disableCalcTrigger,
}) => {
  useCalcTrigger(value, setValue, disableCalcTrigger); // To act like the input onChange event

  const hasDefaultValueRef = useRef(false);

  //#region Callbacks

  /**
   * Set the input value.
   * @param {string} value  The new input value.
   */
  const setInputValue = useCallback(
    (value) => {
      // Prevent setting input to null or undefined
      // Controlled components should not have null or undefined value
      if (typeof value === "undefined" || value === null) return;

      setValue(value);
    },
    [setValue]
  );

  //#endregion

  //#region Side-effects

  /**
   * Default value
   * Set default value here to trigger calculation parser,
   * otherwise calculation strings won't be evaluated.
   */
  useEffect(() => {
    if (!defaultValue || !setInputValue || hasDefaultValueRef.current) return;

    if (isNullEmptyOrWhitespace(value)) {
      setInputValue(defaultValue);
      hasDefaultValueRef.current = true;
    }
  }, [value, defaultValue, setInputValue]);

  //#endregion

  // Prevent dom element rendering
  if (render === false) {
    return null;
  }

  return (
    <div className={className}>
      {label}
      {!isNullEmptyOrWhitespace(label) ? " " : null}
      {value}
    </div>
  );
};

Html.propTypes = {
  value: PropTypes.string,
};

export { Html };
