import Resizer from "react-image-file-resizer";

/**
 * Resize and compress image
 * @param {File}  file      The file to compress
 * @param {{}}    options   Resize and compression options
 * @see https://www.npmjs.com/package/react-image-file-resizer
 */
export const resizeImage = (
  file,
  {
    maxWidth = 300,
    maxHeight = 300,
    compressFormat = "JPEG",
    quality = 100,
    rotation = 0,
    outputType = "file",
    minWidth = 0,
    minHeight = 0,
  }
) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // File
      maxWidth, // maxWidth
      maxHeight, // maxHeight
      compressFormat, // compressFormat
      quality, // quality
      rotation, // rotation
      (uri) => {
        resolve(uri);
      }, // responseUriFunc
      outputType, // outputType
      minWidth, // minWidth
      minHeight // minHeight
    );
  });
};
