import classNames from "classnames";
import React from "react";
import ListSkeleton from "./core/Lists/ListSkeleton";

interface CardProps {
  id?: string;
  className?: string;
  bodyClassName?: string;
  children: React.ReactNode;
  theme?: "primary";
}

export default function Card({ id, theme, className, bodyClassName, children }: CardProps) {
  return (
    <div
      id={id}
      className={classNames(
        className,
        theme === "primary" ? "bg-primary text-white" : "bg-white dark:bg-gray-800 dark:text-white",
        "rounded-lg  shadow"
      )}
    >
      <div className={classNames(
        "px-4 py-5",
        bodyClassName
        )}>{children}</div>
    </div>
  );
}

interface CardTitleProps {
  className?: string;
  children: React.ReactNode;
}

export function CardTitle({ children, className }: CardTitleProps) {
  return (
    <div
      data-cy="title"
      className={classNames(
        "text-base font-medium flex flex-col uppercase",
        className
      )}
    >
      <div className="w-2 self-stretch bg-primary mr-2" />
      <div className="flex">{children}</div>
    </div>
  );
}


export function CardSkeleton() {
  return (
    <Card>
      <ListSkeleton />
    </Card>
  );
}