import { useEffect, useState, useRef } from "react";
import Odometer from "react-odometerjs";

/**
 * Basic lightweight Animated number counter
 * @param {object}  props
 * @param {number|string}  props.start  - The number to start animating from.
 * @param {number|string}  props.end  - The number to end animating on.
 * @param {number}  props.duration  - The number of milliseconds for the animation to complete.
 * @returns animated number
 */
export function Counter({ start = 0, end, duration = 1500 }) {
  start = start < 0 ? 0 : start; // Prevent starting in negative numbers
  let startTimestamp = null;
  const [count, setCount] = useState(start);

  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef();
  // const previousTimeRef = React.useRef();

  const animate = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    setCount(Math.floor(progress * (end - start) + start));

    if (start <= end && progress < 1) {
      window.requestAnimationFrame(animate);
    }
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Make sure the effect runs only once

  return Math.round(count);
}

export function FancyCounter({ value = 0, format = "(,ddd).dd", ...other }) {

  value = value.toString();
  const valueLength = value.length;
  const [count, setCount] = useState(Math.round(value / valueLength));

  useEffect(() => {
    setCount(value)
  }, [value]);

  return <Odometer value={count} format={format} {...other} />;
}
