import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Select,
  Text,
  Input,
  TextArea,
  Datepicker,
  Toggle,
  Upload,
  TimePicker,
  DateTimePicker,
  Html,
  MultiSelect,
} from "components/core";
import { ButtonGroup } from "components/core/Forms/ButtonGroup";
import {
  isNullEmptyOrWhitespace,
  isNull,
  isNumeric,
  parseJSON,
} from "helpers/stringUtilities";
import { formValuesToObject, parseJsonLogic } from "helpers/formsUtilities";
import { AutoComplete } from "components/core";
import {
  useDeepCompareEffectNoCheck,
} from "use-deep-compare-effect";
import { endOfFromDate, localDate } from "helpers/dateUtilities";
import NonConformanceReadonlyField from "components/forms/NonConformanceReadonlyField";
import classNames from "classnames";
import { getFormValueFromPenData } from "helpers/formUtilities";

/**
 * Component to render a form field.
 * @param {import("./types/FormField").IFormFieldProps} props
 * @returns {JSX} Returns JSX to render a form field.
 */
export default function FormField({
  id,
  field,
  penNumber,
  formValues: penFormValues = {},
  prevFormValues,
  setFieldValue,
  setFieldValid,
  validateField,
  standards,
  birdAge,
  house,
  startDate = null,
  endDate = null,
  groupFieldIndex = null,
  wrapperClassName = "",
  setIsCollapsible,
  collapsed,
  labelPosition,
  labelSize,
  ...other
}) {
  const fieldValue = useMemo(
    () =>
      getFormValueFromPenData({
        ref: id,
        questionGroup: field?.QuestionGroup,
        // repeaterId: field?.RepeaterID,
        penData: penFormValues,
      }),
    [field?.QuestionGroup, /*field?.RepeaterID,*/ id, penFormValues]
  );
  const FieldComponent = getFieldType(field);
  const inputType = getInputType(field.FieldType, field.Display);
  const isFieldTypeWithListOptions = ["dd", "mdd", "bg"].includes(
    field.FieldType?.toLowerCase()
  );
  const fieldEditDependencies = field.Display?.edit?.dependencies;

  // const fieldList = field?.List;

  // const listOptions = useMemo(() => {
  //   if (!isFieldTypeWithListOptions) return undefined; // Only dropdowns and button groups have list options
  //   if (!isNullEmptyOrWhitespace(fieldListOptions)) return fieldListOptions; // Use the list options from the field
  //   if (!farms) return undefined; // No farms loaded yet

  //   let result = [];

  //   const regex = /\${([^{}]+)}/gi;
  //   const matched = fieldList.match(regex);
  //   console.log("matched", matched);
  //   if (matched[0]) {
  //     const { ref } = parseCustomLogicVariable(matched[0], {});
  //     if (ref === "farms") {
  //       result = farmListOptions;
  //     } else if (ref === "housenumbers") {
  //       result =  farmHouseNumberListOptions;
  //     } else if (ref === "farm_flocks") {
  //       result =  farmFlockDateListOptions;
  //     } else if (ref === "farm_flock_houses") {
  //       result =  farmFlockHouseListOptions;
  //     }
  //   }

  //   // Remove empty list options
  //   result = result.filter(
  //     (li) =>
  //       !isNullEmptyOrWhitespace(li.Id) &&
  //       !isNullEmptyOrWhitespace(li.Text) &&
  //       !isNullEmptyOrWhitespace(li.Value)
  //   );

  //   return result;
  // }, [isFieldTypeWithListOptions, fieldListOptions, farms, fieldList, farmListOptions, farmHouseNumberListOptions, farmFlockDateListOptions, farmFlockHouseListOptions]);

  const filteredListOptions = useMemo(() => {
    if (!isFieldTypeWithListOptions) return undefined; // Only dropdowns and button groups have list options
    
    const fieldListOptions = field?.ListOptions.filter((lo) => 
      !isNullEmptyOrWhitespace(lo.Value) && !isNullEmptyOrWhitespace(lo.Text) // Remove empty list options
    );

    if (!fieldListOptions?.length) return undefined;

    let result = fieldListOptions;

    // prettier-ignore
    // console.log("filtered list options", field.Ref, "isFieldTypeWithListOptions", isFieldTypeWithListOptions, "penFormValues", penFormValues, "fieldListOptions", fieldListOptions, "fieldEditDependencies", fieldEditDependencies);
    // end prettier-ignore

    // Select component
    // Filter by dependencies
    if (
      !isNullEmptyOrWhitespace(fieldEditDependencies) &&
      fieldEditDependencies instanceof Array
    ) {
      const matchingFormValuesLookup = new Map();
      // Iterate through dependencies
      for (const dependency of fieldEditDependencies) {
        // Find matching pen form value
        const matchingFormValue = penFormValues?.Values?.find(
          (v) => v.Ref.toLowerCase() === dependency.toLowerCase()
        );

        if (matchingFormValue !== undefined) {
          // Add to lookup
          matchingFormValuesLookup.set(dependency, matchingFormValue.Value);
        }
      }

      // Filter list options by dependency form values
      // console.log("_dependenciesFormValues", _dependenciesFormValues, result);
      const dependencyValues = Array.from(matchingFormValuesLookup.values());

      result = fieldListOptions.filter((li) => {
        // add list options with no parent
        if (isNullEmptyOrWhitespace(li.Parent)) return true;

        if (dependencyValues?.length < 1) return false;

        if (li.Parent instanceof Array) {
          // Parent is an array
          return dependencyValues.every((dv) => li.Parent.includes(dv));
        }

        return dependencyValues.every(
          (dv) =>
            li.Parent?.toString()?.toLowerCase() ===
            dv?.toString()?.toLowerCase()
        );
      });
    }

    return result;

    
  }, [isFieldTypeWithListOptions, penFormValues, field?.ListOptions, fieldEditDependencies]);

  //#region State

  const [standard, setStandard] = useState(undefined);
  const [size, setSize] = useState(undefined);
  const [render, setRender] = useState(field.render);
  // const [listOptions, setListOptions] = useState([]);
  const [dependencies] = useState(field.Display?.edit?.dependencies);

  //#endregion

  //#region Callbacks

  const handleSetValue = (value) => {
    if (!setFieldValue) return;

    if (isFieldTypeWithListOptions) {
      field.FilteredListOptions = filteredListOptions;
    }

    const newFieldValue = {
      Ref: id, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
      Value: value,
    };

    // if (!isNullEmptyOrWhitespace(field.RepeaterID)) {
    //   newFieldValue.RepeaterID = field.RepeaterID;
    // }

    setFieldValue(field, penNumber, newFieldValue);
  };

  const handleSetValid = (valid, value, required, complete) => {
    if (!setFieldValid) return;
    setFieldValid(id, field, penNumber, valid, complete, required);
  };

  const handleValidateField = (value) => {
    if (!validateField) return;

    // validateField(id, field, penNumber, value);
    validateField(field, penNumber, value);
  };

  function isRequiredField() {
    // TODO: optimise by moving this to parent component
    const formValuesAsObj = formValuesToObject(
      !isNullEmptyOrWhitespace(penFormValues) ? [penFormValues] : []
    );

    const parsedRequired = parseJsonLogic(field.Required, {
      ...formValuesAsObj,
    });

    if (penNumber.toString() === "1") {
      // Pen 1 fields are always required
      return parsedRequired?.toLowerCase() === "d" ? true : false;
    }

    if (
      penFormValues?.BirdsAlive?.BirdsAlive > 0 ||
      penFormValues?.Values?.some((fv) => !!fv.Value.toString())
    ) {
      // pen has birds alive
      // pen contains a value
      return parsedRequired?.toLowerCase() === "d" ? true : false;
    }

    return false;
  }

  /**
   * Get the label position based on the component name.
   * @param {string} fieldType
   * @returns {string}  The label position.
   */
  function getLabelPosition(componentName) {
    if (isNullEmptyOrWhitespace(componentName)) return;
    if (!isNullEmptyOrWhitespace(labelPosition)) return labelPosition;

    const fieldType = field.FieldType.toLowerCase();
    if (fieldType === "cf" || fieldType === "cfr") return "left";
    if (componentName === "NonConformanceReadonlyField") return "left";

    return "inset";
  }

  /**
   * Conditional build prop values to avoid passing unneccessary props
   */
  const getOptionalProps = () => {
    const optionalProps = {};
    if (FieldComponent.name === Upload.name) {
      optionalProps.uploadLimit = field.Display?.edit?.limit;
      optionalProps.accept = field.Display?.edit?.accept;
    } else if (FieldComponent.name === Datepicker.name) {
      // Datepicker component

      if (field.FieldType.toLowerCase() === "rdp") {
        // Restricted date picker
        const endOfToday = endOfFromDate(localDate(), "day");
        optionalProps.startDate = startDate;

        // Prevent future dates from being clicked
        if (!endDate || endDate.getTime() > endOfToday.getTime())
          optionalProps.endDate = endOfToday;
        else optionalProps.endDate = endDate;
      }
    } else if (FieldComponent.name === ButtonGroup.name) {
      // Button component
      // increase label size of first field in group
      optionalProps.labelSize = groupFieldIndex === 0 ? "large" : null;
      optionalProps.listOptions = filteredListOptions;
    } else if (FieldComponent.name === Html.name) {
      // HTML component
      optionalProps.className = "text-sm";
      const color = field?.Display?.edit?.color?.toLowerCase();
      if (color === "error") {
        optionalProps.className += " text-danger-600";
      } else if (color === "warning") {
        optionalProps.className += " text-warning-600";
      } else if (color === "success") {
        optionalProps.className += " text-success-600";
      } else {
        optionalProps.className += " text-gray-500";
      }
    } else if ([MultiSelect.name].includes(FieldComponent.name)) {
      // MultiSelect OR Select component
      optionalProps.listOptions = filteredListOptions;
      optionalProps.limit = field.Display?.edit?.limit;
      optionalProps.showSearch = field.Display?.edit?.searchable;
    } else if ([Select.name].includes(FieldComponent.name)) {
      // MultiSelect OR Select component
      optionalProps.listOptions = filteredListOptions;

      if (field.Display?.edit?.searchable) {
        optionalProps.showSearch = field.Display?.edit?.searchable;
      }
    } else if (FieldComponent.name === AutoComplete.name) {
      // AutoComplete
      optionalProps.suggestions = filteredListOptions?.map((li) => li);
    } else if (FieldComponent.name === NonConformanceReadonlyField.name) {
      // NonConformanceReadonlyField
      optionalProps.formValue = fieldValue;
      optionalProps.field = field;
      optionalProps.severityColour = filteredListOptions?.find(
        (li) => li.Value === fieldValue?.Value
      )?.SeverityColour;
    } else if (FieldComponent.name === Input.name) {
      // Input
      if (field.FieldType.toLowerCase() === "f") {
        optionalProps.step = "any";
        optionalProps.minTolerance = field.MinTol;
        optionalProps.maxTolerance = field.MaxTol;
      }
    }

    return optionalProps;
  };

  const handleSetCollapsible = useCallback(
    (collapsed) => {
      if (!setIsCollapsible) return; // Not grouped

      setIsCollapsible(field.Ref, collapsed);
    },
    [setIsCollapsible, field?.Ref]
  );

  //#endregion

  //#region Side-effects

  /**
   * Set standard
   */
  useEffect(() => {
    if (!field?.Std || !standards?.length || !birdAge?.days) return;

    setStandard(
      standards.find(
        (s) =>
          s.ID.toString() === field.Std.toString() &&
          s.Days.toString() === birdAge.days.toString()
      )
    );
  }, [standards, birdAge?.days, field?.Std]);

  /**
   * Set field size
   */
  useEffect(() => {
    if (isNull(field)) return;

    if (field.Display?.edit?.size) {
      setSize(field.Display?.edit.size);
    }
  }, [field]);

  /**
   * Initially set collapsible & collapsed
   */
  useDeepCompareEffectNoCheck(() => {
    if (!handleSetCollapsible) return;
    // Display
    // Collapsed
    const collapsed = field.Display?.edit?.collapsed;

    const formValuesAsObj = formValuesToObject(
      !isNullEmptyOrWhitespace(penFormValues) ? [penFormValues] : []
    );
    const parsedCollapsed = parseJsonLogic(parseJSON(collapsed), {
      this: !isNullEmptyOrWhitespace(fieldValue?.Value)
        ? fieldValue.Value
        : null,
      ...formValuesAsObj,
    });

    if (typeof parsedCollapsed == "boolean" || parsedCollapsed === null) {
      handleSetCollapsible(parsedCollapsed);
    }
  }, [
    field?.Display,
    field?.Ref,
    penFormValues,
    handleSetCollapsible,
    fieldValue,
  ]);

  /**
   * Set render
   */
  useDeepCompareEffectNoCheck(() => {
    if (isNull(field?.Ref)) return;

    const isRenderedField = () => {
      let _render = field.render;
      // console.log("isRenderedField", field.Ref, _render);
      if (_render === false) {
        // Respect hidden elements set in parent(s)
        setRender(_render);
        return;
      }

      // Display
      // Position
      const position = field.Display?.edit?.position;

      const formValuesAsObj = formValuesToObject(
        !isNullEmptyOrWhitespace(penFormValues) ? [penFormValues] : []
      );
      const prevFormValuesAsObj = formValuesToObject(prevFormValues?.PenValues);
      const parsedPosition = parseJsonLogic(parseJSON(position), {
        // this: !isNullEmptyOrWhitespace(fieldValue?.Value)
        //   ? fieldValue.Value
        //   : null,
        previous: prevFormValuesAsObj,
        ...formValuesAsObj,
      });

      if (field.Display?.toString()?.toLowerCase() === "h") {
        // { "Display": "h" }
        _render = false;
      } else if (parsedPosition === null) {
        // { "Display": { "edit": { "position" : null }}}
        _render = false;
      } else if (
        isNumeric(parsedPosition) ||
        isNullEmptyOrWhitespace(parsedPosition)
      ) {
        // { "Display": { "edit": { "position" : 1 }}}
        _render = true;
      }

      // Collapsed
      if (_render && collapsed === true) {
        // Collapsed, don't continue to check position
        _render = false;
      }

      setRender(_render);
    };

    isRenderedField();
  }, [
    field.Display,
    field.render,
    field.Ref,
    penFormValues,
    collapsed,
    fieldValue?.Value,
    prevFormValues,
  ]);

  //#endregion

  const fieldComponent = (
    <FieldComponent
      id={`${field.Ref}-${field.Level}${penNumber}`}
      key={`${field.Ref}-${field.Level}${penNumber}`}
      label={field.Name}
      hint={field.Description}
      type={inputType}
      labelPosition={getLabelPosition(FieldComponent.name)}
      labelSize={labelSize}
      value={fieldValue?.Value}
      setValue={handleSetValue}
      setValid={handleSetValid}
      validate={handleValidateField}
      required={isRequiredField()}
      addonLeft={field.Prefix}
      addonRight={field.Suffix}
      defaultValue={field.DefaultValue}
      dependencies={dependencies}
      // size={size}
      render={render}
      // hint={`Ref: ${field.Ref}. Calculation: ${field.Calculation}.`}
      {...getOptionalProps()}
    />
  );

  // Only render FieldComponent Wrapper if field is rendered
  return (
    <FieldComponentWrapper
      size={size}
      standard={standard}
      inputType={inputType}
      className={classNames(render === false && "hidden", wrapperClassName)}
    >
      {fieldComponent}
    </FieldComponentWrapper>
  );
}

/**
 * Get the component input type based on the field type.
 * @param {object} field
 * @returns {string}  The label position.
 */
function getFieldType(field) {
  const fieldType = !!field.FieldType
    ? field.FieldType.toLowerCase()
    : field.FieldType;

  // Handle special cases
  if (field.Readonly) {
    // Non-Conformance readonly field{
    return NonConformanceReadonlyField;
  }

  switch (fieldType) {
    case "ac": // Autocomplete
      return AutoComplete;
    case "up": // Upload
      return Upload;
    case "cb": // Checkbox
      return Toggle;
    case "cf": // Calculated field
    case "cfr": // Calculated field
      return Text;
    case "dd": // Dropdown
      return Select;
    case "mdd": // Multi-select Dropdown
      return MultiSelect;
    case "bg": // Button Group
      return ButtonGroup;
    case "sl": // Comment
      return TextArea;
    case "dp": // Datepicker
    case "rdp": // Restricted Datetimepicker
      return Datepicker;
    case "dtp": // Datetimepicker
      return DateTimePicker;
    case "t":
      return TimePicker;
    case "tx": // HTML text
      return Html;
    case "f": // Float
    case "i": // Integer
    default:
      return Input;
  }
}

/**
 * Get the textbox input type based on the field type.
 * @param {string} fieldType
 * @returns {string}  The label position.
 */
function getInputType(fieldType, display) {
  fieldType = !!fieldType ? fieldType.toLowerCase() : fieldType;

  // Override with display value
  if (typeof display == "string" && display?.toLowerCase() === "h")
    return "hidden";

  switch (fieldType) {
    case "f":
    case "i":
      return "number";
    case "h":
      return "hidden";
    case "s":
      return "text";
    default:
      return undefined;
  }
}

/**
 *
 * @param {object} props
 * @returns
 */
function FieldComponentWrapper({
  size,
  standard,
  children,
  inputType,
  className = "",
  ...other
}) {
  if (inputType === "hidden") return children;

  className = `${className} ${getColSpan()} grid grid-cols-3 gap-2 items-center`;

  function getColSpan() {
    if (size === "small") {
      return "col-span-2";
    } else if (size === "xsmall") {
      return "col-span-1";
    } else if (size === "medium") {
      return "col-span-3";
    } else {
      return "col-span-4";
    }
  }

  return (
    <div {...other} className={className}>
      <div className={!!standard ? "col-span-2" : "col-span-3"}>{children}</div>
      {!!standard && (
        <div className="col-span-1">
          <Text
            id={`${standard.ID}-${standard.StdNo}-${standard.Days}`}
            label={standard.StdName}
            value={standard.Value}
            labelPosition="inset"
          />
        </div>
      )}
    </div>
  );
}
