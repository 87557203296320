import { Modal } from "components/core";
import React, { useState, createContext } from "react";

export const ModalContext = createContext(undefined);

export function ModalProvider({ children }) {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(undefined);

  /**
   * @param {boolean} show 
   * @param {React.ReactNode} content 
   */
  const setShowModal = (show, content) => {
    if (show) {
      setContent(content);
      setShow(true);
    } else {
      setContent(undefined);
      setShow(false);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        setShowModal,
      }}
    >
      {children}
      <Modal open={show} onOpen={(_open) => setShow(_open)} showCancel={false}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
}


/**
 * @returns {{setShowModal: (show: boolean, content: React.ReactNode) => void}}
 */
export const useModal = () => {
  const context = React.useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
}