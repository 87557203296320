import { Suspense, lazy } from "react";
import { Button, ButtonClicked } from "components/core";
import {
  CheckCircleIcon,
  EditCircleIcon,
  ExclamationCircleIcon,
} from "assets/icons";
import { DATA_STATUS } from "constants.js";

const HorizontalProgress = lazy(() =>
  import("components/Progress/HorizontalProgress")
);

/**
 * Component to render form buttons.
 * @param {object} props
 * @returns {JSX} Returns JSX to render form buttons.
 */
const FormButtons = ({
  _ref,
  dataStatus,
  loaded,
  handleClickCancel = null,
  showDraft = false,
  requiredFieldCount,
  requiredFieldCompletedCount,
  className = "",
  size,
  button,
  isProcessingButtonVisible = false,
  ...other
}) => {
  const showProgress = !!requiredFieldCount ? true : false;
  return (
    <>
      <div ref={_ref}></div> {/* Sticky limit */}
      <div {...other} className={`${className}`}>
        <div className="grid grid-cols-6 gap-1 px-4 items-center">
          <div className="col-span-full">{button}</div>

          {showProgress && (
            <div className="col-span-6 flex flex-col items-center">
              <Suspense
                fallback={
                  <div>
                    {0}/{0}
                  </div>
                }
              >
                <HorizontalProgress
                  value={
                    (requiredFieldCompletedCount / requiredFieldCount) * 100
                  }
                  text={`${requiredFieldCompletedCount}/${requiredFieldCount}`}
                />
              </Suspense>
            </div>
          )}

          <div className="col-span-6">
            <div className="flex space-x-2 w-full tablet:px-0">
              {isProcessingButtonVisible ? (
                <ButtonClicked />
              ) : (
                <>
                  <div className="grow">
                    <Button
                      className="justify-self-start"
                      type="button"
                      onClick={handleClickCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                  <Button
                    type="submit"
                    theme={
                      dataStatus === DATA_STATUS.COMPLETE
                        ? "primary"
                        : showDraft
                        ? "gray"
                        : dataStatus === DATA_STATUS.ERROR
                        ? "danger"
                        : null
                    }
                    disabled={
                      dataStatus === DATA_STATUS.ERROR ||
                      (dataStatus !== DATA_STATUS.COMPLETE && !showDraft)
                    }
                    optionsContainerClassName=""
                    icon={
                      dataStatus === DATA_STATUS.COMPLETE ? (
                        <CheckCircleIcon className="h-5 w-5 mr-1" />
                      ) : dataStatus === DATA_STATUS.ERROR ? (
                        <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                      ) : showDraft ? (
                        <EditCircleIcon className="h-5 w-5 mr-1" />
                      ) : (
                        <CheckCircleIcon className="h-5 w-5 mr-1" />
                      )
                    }
                  >
                    {dataStatus === DATA_STATUS.COMPLETE
                      ? `Save`
                      : dataStatus === DATA_STATUS.ERROR
                      ? `Invalid Data`
                      : showDraft
                      ? `Save Draft`
                      : `Save`}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormButtons;
