import React from "react";
import ListItemSkeleton from "./ListItemSkeleton";

export default function ListSkeleton({ ...other }) {
  const classesArr = [];

  return (
    <div className="animate-pulse">
      <ul
        className={`divide-y divide-gray-200 ${classesArr.join(" ")}`}
        {...other}
      >
        <ListItemSkeleton />
        <ListItemSkeleton />
      </ul>
    </div>
  );
}
