import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { Button } from "components/core";
import { LockClosedIcon } from "assets/icons";
import { Input } from "components/core";
import Logo from "assets/Logo";
import { useUser } from "context/AppDataProvider";
import Card from "components/Card";
import { useNotifications } from "context/NotificationProvider";

// Fetch customer related images by subdomain
let subdomain = window.location.hostname.split(".").reverse()[2];

/**
 * Uncomment the following line to test on local development
 * overwrite subdomain with client subdomain
 */
// if (process.env.NODE_ENV === "development") subdomain = "ahwnistaging";

const expectedFooterLogoSrc = `/${subdomain}/images/login-footer-logos.jpg`;


export default function Login() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/" } };

  const { addNotification } = useNotifications();
  const { isSignedIn } = useUser();

  //#region state

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [footerLogoSrc, setFooterLogoSrc] = useState("");

  // Validation states
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  //#endregion
  
  useEffect(() => {
    fetch(expectedFooterLogoSrc)
      .then((res) => {
        if (res.status === 200) {
          setFooterLogoSrc(expectedFooterLogoSrc);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //#region event listeners

  const handleLogin = (ev) => {
    ev.preventDefault();

    if (!formValid()) return; // Prevent invalid submission

    auth.signin(username, password, (response) => {
      if (response.isAuthenticated) {
        addNotification({
          key: "login-success-notification",
          title: "Login success",
          theme: "success",
          description: "You have successfully logged in.",
        });
        navigate(from, { replace: true });
      } else {
        addNotification({
          key: "login-failed-notification",
          title: "Login failed",
          theme: "error",
          description:
            "Check your user credentials and try again. If the problem persists please contact support.",
        });
      }
    });
  };

  //#endregion

  //#region validation

  const formValid = () => {
    if (
      !usernameValid ||
      !passwordValid
    ) {
      return false;
    }

    return true;
  };

  const validUsername = (value) => {
    return;
  };

  const validPassword = (value) => {
    return;
  };

  //#endregion

  if (isSignedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex flex-1 items-center text-center bg-gray-100">
      <div className="flex-1 flex-row space-y-2 justify-center">
        <div className="mx-auto w-full p-4 max-w-xl tablet:p-0">
          <Card className="p-4 tablet:p-8">
            <div>
              <Logo className="h-24 w-auto mx-auto text-gray-900" />
              <h1 className="sr-only">
                Sign in to your account
              </h1>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={handleLogin} className="space-y-6">
                  <Input
                    id="username"
                    label="Username"
                    type="text"
                    value={username}
                    labelPosition="inset"
                    setValue={setUsername}
                    setValid={setUsernameValid}
                    validate={validUsername}
                    disableCalcTrigger={true}
                  />

                  <Input
                    id="password"
                    label="Password"
                    type="password"
                    value={password}
                    labelPosition="inset"
                    setValue={setPassword}
                    setValid={setPasswordValid}
                    validate={validPassword}
                    disableCalcTrigger={true}
                  />

                  <Button
                    type="submit"
                    theme="primary"
                    size="large"
                    isFullWidth
                    className="relative"
                    disabled={!formValid()}
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <LockClosedIcon className="h-5 w-5 text-primary-light group-hover:text-primary-light" />
                    </span>
                    Sign in
                  </Button>
                </form>
              </div>
            </div>
          </Card>
        </div>

        <div className="mx-auto w-60 tablet:w-72">
          {footerLogoSrc ? (
            <div className="">
              <img src={footerLogoSrc} alt="login footer logos" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
