import { useMenus } from "context/AppDataProvider";
import { buildListPageUrl } from "helpers/redirectUtilities";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { List, ListItem } from "./core";
import ListSkeleton from "./core/Lists/ListSkeleton";
import { IMenuItem } from "./Sidebar";

interface Props {
  className?: string;
}

export default function UsefulLinks({ className }: Props) {
  const { menus } = useMenus();
  const navigate = useNavigate();

  const topMenuItems = useMemo(() => {
    let result: IMenuItem[] = [];
    let counter = 0;
    const maxItems = 3;

    for (const parentMenu of menus) {
      if (counter >= maxItems) {
        break;
      }
      if (parentMenu.Children === undefined) {
        continue;
      }
      const children = parentMenu.Children.filter((c) => c.Path !== "/");
      result.push({
        ...parentMenu,
        Children: children.slice(0, maxItems - counter),
      });
      counter += children.length;
    }

    return result;
  }, [menus]);

  const handleOnClickUsefulLinks = (
    childMenu: IMenuItem
  ) => {
    return navigate(buildListPageUrl(childMenu));
  };

  return topMenuItems.length > 0 ? (
    <List size="small" theme="striped" className={className}>
      {topMenuItems.map((parentMenu) => {
        if (parentMenu.Children === undefined) {
          return null;
        }

        return parentMenu.Children.map((menu) => (
          <ListItem onClick={() => handleOnClickUsefulLinks(menu)}>
            <span>{parentMenu.Title}</span>{" "}
            <span className="text-gray-300">&nbsp;/&nbsp;</span>{" "}
            <span className="text-primary">{menu.Title}</span>
          </ListItem>
        ));
      })}
    </List>
  ) : (
    <ListSkeleton />
  );
}
