import React from "react";
export default function FieldsetSkeleton({ showDivider = false }) {
  return (
    <div className="animate-pulse">
      <div>
        <div className="laptop:grid laptop:grid-cols-3 laptop:gap-6">
          <div className="laptop:col-span-1">
            <div className="">
              <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
              <div className="mt-1 h-2 bg-gray-300 rounded"></div>
              <div className="mt-1 w-2/3 h-2 bg-gray-300 rounded"></div>
            </div>
          </div>
          <div className="mt-5 laptop:mt-0 laptop:col-span-2">
            <div className="shadow tablet:rounded-md tablet:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 tablet:p-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="h-8 bg-gray-300 rounded"></div>
                  <div className="h-8 bg-gray-300 rounded"></div>
                </div>
                <div className="h-8 bg-gray-300 rounded"></div>
                <div className="h-8 bg-gray-300 rounded"></div>
                <div className="h-16 bg-gray-300 rounded"></div>
                <div className="h-8 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDivider && (
        <div className="block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
      )}
    </div>
  );
}
