
export function overrideNativeConsole(callback: ({ message, level }: { message: any, level: "log" | "warn" | "error" }) => void) {
  const nativeConsole = window?.console;
  const newConsole = {
    log: nativeConsole.log,
    warn: nativeConsole.warn,
    error: nativeConsole.error,
  };

  if (nativeConsole) {
    // We currently only override the console.error methods for log storing
    // nativeConsole.log = (...args: any[]) => {
    //   newConsole.log(...[args, 'inside useConsole']);
    //   callback && callback({message: args, level: "log"});
    // }

    // nativeConsole.warn = (...args: any[]) => {
    //   newConsole.warn(...[args, 'inside useConsole']);
    //   callback && callback({message: args, level: "warn"});
    // }

    nativeConsole.error = (args: any) => {
      newConsole.error(args);
      callback && callback({message: args, level: "error"});
    }
  }
}