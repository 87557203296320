import { IFormData } from "helpers/formUtilities";

export interface DBFormData {
  ID: number;
  FarmCode: string;
  FormType: string;
  FormName: string;
  HouseNumber: string;
  DateApplies: number;
  LastModified: number;
  Data: IFormData;
}

export const mergeLocalDataWithNetworkData = (
  localData: DBFormData[],
  localEntityIndices: any[],
  networkEntity: { PenValues: any[] },
  matchingLocalEntity: {
    Data: { LastModified: any; Status: any; PenValues: any[] };
  }
) => {
  // Remove from localData to prevent appending it twice
  localData.splice(localEntityIndices[0], 1);

  // Remove index from indices to ensure it isn't deleted
  localEntityIndices.splice(0, 1);

  // Replace network entity PenValues.Values with local
  return {
    ...networkEntity,
    LastModified: matchingLocalEntity.Data.LastModified,
    Status: matchingLocalEntity.Data.Status,
    PenValues: networkEntity.PenValues.map((networkPen) => {
      const matchingLocalPen = matchingLocalEntity.Data.PenValues.find(
        (pv) => pv.Pen.toString() === networkPen.Pen.toString()
      );

      return {
        ...networkPen,
        Values: matchingLocalPen
          ? matchingLocalPen.Values.map((value: any) => value)
          : [],
      };
    }),
  };
};
