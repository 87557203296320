import { useContext, useState } from "react";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import MediaUploadForm from "components/forms/MediaUploadForm";
import { AppDataContext, useUser } from "context/AppDataProvider";
import { Alert } from "components/core";
import { useParams } from "react-router-dom";
import { useActiveMenu } from "hooks/useActiveMenu";
import MediaPreview from "components/forms/MediaPreview";

export default function FormsPage() {
  const { formId } = useParams();
  const { forms } = useContext(AppDataContext);
  const { offline } = useUser();
  const { activeMenu } = useActiveMenu();

  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const [pageTitle, setPageTitle] = useState<string | React.ReactNode>(
    undefined
  );
  const [pageSubtitle] = useState<string | React.ReactNode>(
    undefined
  );

  const isFormsLoaded = forms !== undefined && forms.length > 0;

  const canEditForms = forms.some(
    (f) =>
      f.FormName?.toLowerCase() === formId?.toLowerCase() &&
      f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
      f.ModuleName?.toLowerCase() === module?.toLowerCase()
  );

  if (offline) {
    return (
      <Alert theme="warning" className="mb-6">
        You are offline. The media library requires an active internet connection.
      </Alert>
    );
  }

  return (
    <main className="flex flex-col flex-grow">
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {pageTitle !== undefined ? (
              pageTitle
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
          {!isNullEmptyOrWhitespace(pageSubtitle) ? (
            <div className="tablet:inline-flex tablet:space-x-4 text-sm">
              {pageSubtitle}
            </div>
          ) : null}
        </div>
        <div className="col-span-full">
          {isFormsLoaded ? (canEditForms ? (
            <MediaUploadForm setPageTitle={setPageTitle} />
          ) : (
            <MediaPreview setPageTitle={setPageTitle} />
          )) : null}
        </div>
      </div>
    </main>
  );
}
