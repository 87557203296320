import classNames from "classnames";
import { IChartKey, IChartKeyOperand } from "../types";

interface IOperandSummaryProps {
  title: string;
  operand: IChartKeyOperand;
  chartKey: IChartKey;
  className?: string;
}

export default function OperandSummary({ title, operand, chartKey, className }: IOperandSummaryProps) {
  return (
    <div className={classNames(className)}>
      {title}
      <span className="font-bold">{chartKey.title}</span> {operand.operator}{" "}
      {operand.tolerance !== undefined && (operand.tolerance > 0 || operand.tolerance < 0) ? "(" : null}
      <span className="font-bold">
        {operand.values
          ?.map((v, i) => (
            <span>
              {v.value}
              {operand.tolerance !== undefined && (operand.tolerance > 0 || operand.tolerance < 0)
                ? ` + ${operand.tolerance}${
                    operand.toleranceUnit === "number" ? "" : "%"
                  }`
                : ""}
              {i < operand.values?.length-1 ? " & " : null}
            </span>
          ))}
      </span>
      {operand.tolerance !== undefined && (operand.tolerance > 0 || operand.tolerance < 0) ? ")" : null}
      <span
        className="w-2 h-2 ml-2 inline-block rounded-full"
        style={{ backgroundColor: operand.color }}
      />
    </div>
  );
}
