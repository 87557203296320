import classNames from "classnames";
import React from "react";

export interface TabsProps {
  tabs: ITab[];
  onChange: (tabId: ITab["id"]) => void;
  className?: string;
}

export interface ITab {
  id: string;
  name: string;
  current: boolean;
}

export default function Tabs({ tabs, onChange, className }: TabsProps) {
  return (
    <div className={className}>
      <div className="tablet:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(ev) => onChange(ev.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden tablet:block">
        <nav className="flex" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              onClick={() => onChange(tab.id)}
              className={classNames(
                tab.current
                  ? "bg-white text-primary border-primary"
                  : "bg-transparent text-gray-600 border-gray-300 hover:bg-primary hover:text-white hover:border-primary",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group border py-2 px-4 shadow focus:outline-none focus:ring-0 text-sm"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}
